import { child, equalTo, get, onChildAdded, orderByChild, query, ref, update } from 'firebase/database'
import React, { useEffect, useRef, useState } from 'react'
import { db, storage } from '../../firebase'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import countries from '../../arrays/countries.json'
import languages from '../../arrays/languages.json'
import genres from '../../arrays/genres.json'
import { Link } from 'react-router-dom';
import { getDownloadURL, uploadBytes, ref as sRef } from 'firebase/storage'
import JSZip from 'jszip';
import axios from 'axios';
import FileSaver from 'file-saver';
import Pagination from '../Paginations';

const AdminReleases = () => {

    const [releases, setReleases] = useState([])
    const [filteredReleases, setFilteredReleases] = useState([])
    const [users, setUsers] = useState([])
    const [platforms, setPlatforms] = useState([])

    const [tab, setTab] = useState(1)

    const statusClasses = {
        Distributed: 's-green',
        Processing: 's-blue',
        Error: 's-red n-right',
        Takedown: 's-orange'
    }


    useEffect(() => {
        get(ref(db, 'users'))
        .then(snapshot => {
            const usersData = snapshot.val() || {}; // Get the data from the snapshot
            const usersArray = Object.entries(usersData).map(([key, value]) => ({
                id: key, // Include the key as "id" property
                ...value // Spread the rest of the properties
            }));
            setUsers(usersArray)
            const flattenedData = usersArray.flatMap(user => 
                user.releases !== undefined && user.releases.map((release, i) => ({
                    index: i,
                    key: user.id,
                    email: user.email,
                    ...release
                }))
            ).filter(item => item !== false)

            setReleases(flattenedData)
            setFilteredReleases(flattenedData)

        })
        get(ref(db, 'platforms'))
        .then(snapshot => {
            if (snapshot.exists()) {
                const platformsData = snapshot.val() || {};
                const platformsArray = Object.entries(platformsData).map(([key, value]) => ({
                    id: key, 
                    ...value 
                }));
                setPlatforms(platformsArray)
            }
        })
    }, [])


    const handleSearch = (event) => {
        const term = event.target.value;

        setCurrentPage(1)
    
        const filteredResults = releases.filter((release) => {
          const { title, upc } = release;
          const lowerCaseTerm = term.toLowerCase();
    
          // Check if the item's properties contain the search term
          return (
            title && title.toLowerCase().includes(lowerCaseTerm) ||
            upc && upc.toString().includes(lowerCaseTerm.toString())
          );
        });
    
        setFilteredReleases(filteredResults);
    };


    /////////// PAGINATION ///////////
    const itemsPerPage = 25;
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredReleases.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(filteredReleases.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    
    const [loader, setLoader] = useState(false)

    const arrayToCSV = (array) => {
        const header = Object.keys(array[0]).join(',');
        const rows = array.map(obj => Object.values(obj).join(',')).join('\n');
        return `${header}\n${rows}`;
    };

    const exportZip = async (release) => {
        setLoader(true)
        const zip = new JSZip()
        const artworkBlob = await axios.get(release.artwork, { responseType: 'blob' });
        const fileType = artworkBlob.data.type.split('/').pop().toLowerCase().replace('jpeg', 'jpg')
        zip.file(`${release.title || 'cover'}.${fileType}`, artworkBlob.data)
        // zip.file('metadata.txt', `
        //     Title: ${release.title}
        //     Copyright Line: ${release.copyrightLine}
        //     Copyright Year: ${release.copyrightYear}
        //     Publish Line: ${release.publishLine}
        //     Publish Year: ${release.publishYear}
        //     Primary Genre: ${release.primaryGenre}
        //     Secondary Genre: ${release.secondaryGenre}
        //     UPC: ${release.upc}
        //     Distribution: ${release.distribution}
        //     Label: ${release.label}
        // `);

        const tracksCSV = []

        const releasePlatformsTitles = []

        if (release.platforms) {
            release.platforms.forEach(platform => {
                if (platform.status === 'Processing' || platform.status === 'Taken Down') {
                    const platformItem = platforms.find(item => item.id === platform.id)
                    if (platformItem) {
                        releasePlatformsTitles.push(platformItem.title)
                    }
                }
            })
        }

        const tracks = release.tracks;
        const releaseArtistsNames = release.artists ? release.artists.map(artist => `${artist.role}: ${artist.name}`) : []
        const releaseArtists = releaseArtistsNames.join(' / ')
        const releasePlatforms = releasePlatformsTitles.join(' / ')
        for (const track of tracks) {
            if (track.file !== undefined) {
                const trackAudioBlob = await axios.get(track.file, { responseType: 'blob' });
                const path = new URL(track.file).pathname;
                const fileName = path.split('/').pop();
                const fileExtension = fileName.split('.').pop().toLowerCase();

                
                zip.file(`${track.title}.${fileExtension}`, trackAudioBlob.data);
            }
            const trackArtistsNames = track.artists ? track.artists.map(artist => `${artist.role}: ${artist.name}`) : []
            const trackArtists = trackArtistsNames.join(' / ')
            const territories = release.countries.join(' / ')
            tracksCSV.push({
                'Release title': release.title,
                'User email': release.email,
                'UPC': release.upc,
                'Release cover': release.artwork,
                'Label name': release.label,
                'Primary genre': release.primaryGenre,
                'Secondary genre': release.secondaryGenre,
                'Release artists': releaseArtists,
                'Platforms': releasePlatforms,
                'Explicit lyrics': track.explicit,
                'Territories': territories,
                'Track title': track.title,
                'Track filename': track.filename,
                'Track duration': track.duration,
                'Track genre': track.genre,
                'Track language': track.trackLanguage,
                'Start point time': track.clipStart,
                'ISRC code': track.isrc,
                'Track artists': trackArtists,
                'Copyright year': release.copyrightYear,
                'Copyright line': release.copyrightLine,
                'Publishing year': release.publishingYear,
                'Publishing line': release.publishingLine,
                'Original release date': release.originalDate,
                'Digital release date': release.digitalDate
            });
        }

        const tracksCSVContent = arrayToCSV(tracksCSV);
        zip.file(`${release.title}.csv` || 'tracks.csv', tracksCSVContent);
        
        zip.generateAsync({ type: 'blob' }).then((content) => {
            // Save or download the ZIP file
            FileSaver.saveAs(content, release.title || 'release.zip');
        });
        setLoader(false)
    }


    const [sortOrder, setSortOrder] = useState("asc")

    const handleSort = (property) => {
        setCurrentPage(1)
        const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
        const sortedData = [...filteredReleases].sort((a, b) => {
        if (property === "email") {
            return (sortOrder === "asc" ? 1 : -1) * a.email.localeCompare(b.email);
        } else if (property === "title") {
            return (sortOrder === "asc" ? 1 : -1) * a.title.localeCompare(b.title);
        } else if (property === "originalDate") {
            return (sortOrder === "asc" ? 1 : -1) * a.originalDate.localeCompare(b.originalDate);
        }
        return 0;
        });

        setFilteredReleases(sortedData);
        setSortOrder(newSortOrder);
    };

  return (
    <div className="admin-page py-5">
        <div className='container'>
            <h1 className='dash-title'>Releases</h1>
            <div className="row">
                <div className="col-12 mt-4">
                    <div className="dash-card">
                        <div className="dash-card-heading mb-2">
                            <h2 className="dash-card-title">Releases</h2>
                        </div>
                        <input type="text" className='dash-input' onChange={handleSearch} placeholder='Filter by Title, UPC...' />
                        <div className="dash-tabs">
                            <Link 
                                to={'#'} 
                                className={`dash-tab ${tab === 1 && 'active'}`}
                                onClick={() => {
                                    setTab(1); 
                                    setFilteredReleases(releases)
                                    setCurrentPage(1)
                                }}
                                >All</Link>
                            <Link 
                                to={'#'} 
                                className={`dash-tab ${tab === 2 && 'active'}`}
                                onClick={() => {
                                    setTab(2); 
                                    setFilteredReleases(releases.filter(item => item.status === 'Processing'))
                                    setCurrentPage(1)
                                }}
                            >Processing</Link>
                        </div>
                        <div className="table-responsive mt-3">
                            <table className="table dash-table">
                                <thead>
                                    <tr>
                                        <td>Artwork</td>
                                        <td>
                                            <div className='table-sort' onClick={() => handleSort('email')}>
                                                User
                                                {
                                                    sortOrder === "asc" ? <i className="fi fi-ss-angle-small-down"></i>
                                                    : <i className="fi fi-ss-angle-small-up"></i>
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div className='table-sort' onClick={() => handleSort('title')}>
                                                Title
                                                {
                                                    sortOrder === "asc" ? <i className="fi fi-ss-angle-small-down"></i>
                                                    : <i className="fi fi-ss-angle-small-up"></i>
                                                }
                                            </div>
                                        </td>
                                        <td>Genre</td>
                                        <td>
                                            <div className='table-sort' onClick={() => handleSort('originalDate')}>
                                                Date
                                                {
                                                    sortOrder === "asc" ? <i className="fi fi-ss-angle-small-down"></i>
                                                    : <i className="fi fi-ss-angle-small-up"></i>
                                                }
                                            </div>
                                        </td>
                                        <td>Status</td>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tab === 1 && currentItems.map((release, i) => (
                                            <tr key={i}>
                                                <td>
                                                    <div className="table-item">
                                                        <img className='release-img' src={release.artwork} alt="" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        {release.email}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        {release.title}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        {release.primaryGenre}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        {release.originalDate}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                    <div className={`release-status ${statusClasses[release.status]}`}>{release.status}</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item d-flex">
                                                        <Link 
                                                            className="table-action-btn a-blue me-1"
                                                            // data-bs-toggle="modal"
                                                            // data-bs-target="#releaseModal"
                                                            // onClick={() => handleEdit(release.key, release.index)}
                                                            to={`edit/${release.key}/${release.index}`}
                                                        >
                                                            <i className="fi fi-rr-pencil"></i>
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    {
                                        tab === 2 && currentItems.map((release, i) => (
                                                    <tr key={i}>
                                                        <td>
                                                            <div className="table-item">
                                                                <img className='release-img' src={release.artwork} alt="" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="table-item">
                                                                {release.email}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="table-item">
                                                                {release.title}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="table-item">
                                                                {release.primaryGenre}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="table-item">
                                                                {release.originalDate}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="table-item">
                                                            <div className={`release-status ${statusClasses[release.status]}`}>{release.status}</div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="table-item d-flex">
                                                                <Link 
                                                                    className="table-action-btn a-blue me-1"
                                                                    // data-bs-toggle="modal"
                                                                    // data-bs-target="#releaseModal"
                                                                    // onClick={() => handleEdit(release.key, release.index)}
                                                                    to={`edit/${release.key}/${release.index}`}
                                                                >
                                                                    <i className="fi fi-rr-pencil"></i>
                                                                </Link>
                                                                <button 
                                                                    className="table-action-btn a-green"
                                                                    onClick={() => exportZip(release)}
                                                                >
                                                                    <i className="fi fi-br-download"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        {/* <div className="table-pagination">
                            {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                            <button key={pageNumber} className={currentPage === pageNumber && 'active'} onClick={() => handlePageChange(pageNumber)}>
                                {pageNumber}
                            </button>
                            ))}
                        </div> */}
                        <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
                    </div>
                </div>
            </div>

            <div className={`loader-container ${loader && 'active'}`}>
                <div className="loader"></div>
            </div>

        </div>

    </div>
  )
}

export default AdminReleases