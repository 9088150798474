import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import logo from '../../assets/logo.svg'
import { signOut } from 'firebase/auth'
import { auth, db } from '../../firebase'
import { child, get, ref } from 'firebase/database'

const AdminSidebar = () => {

    const navigate = useNavigate()

    const navLinks = [
        { title: 'Users', iconClass: 'fi fi-tr-users-alt', to: 'users' },
        { title: 'Payout Requests', iconClass: 'fi fi-tr-usd-circle', to: 'payouts' },
        { title: 'Roles / Genres', iconClass: 'fi fi-tr-bars-sort', to: 'rolesandgenres' },
        { title: 'Releases', iconClass: 'fi fi-ts-rocket-lunch', to: 'releases' },
        { title: 'Artists', iconClass: 'fi fi-tr-music-note', to: 'artists' },
        { title: 'Labels', iconClass: 'fi fi-tr-ticket-alt', to: 'labels' },
        { title: 'Analytics', iconClass: 'fi fi-tr-stats', to: 'analytics' },
        { title: 'Upload Reports', iconClass: 'fi fi-tr-money-check-edit', to: 'reports' },
        { title: 'Reports History', iconClass: 'fi fi-tr-rectangle-vertical-history', to: 'reportsHistory' },
        { title: 'Platforms', iconClass: 'fi fi-tr-tv-music', to: 'platforms' }
    ];    

    const allPages = navLinks.map(link => link.title)   

    const uid = localStorage.getItem('aid')
    const [userType, setUserType] = useState('')
    const [pages, setPages] = useState([])

    useEffect(() => {
        get(child(ref(db, 'users'), uid))
        .then(snapshot => {
            setUserType(snapshot.val().role)
            if (snapshot.val().role === 'admin') {
                if (snapshot.val().adminRole) {
                    get(child(ref(db, 'roles'), snapshot.val().adminRole))
                    .then(snapshot => {
                        if (snapshot.exists()) {
                            setPages(snapshot.val().features)
                            console.log(snapshot.val().features)
                        } else {
                            setPages(allPages)
                        }
                    })
                } else {
                    setPages(allPages)
                }
            } else {
                setPages(allPages)
            }
        })
    }, [])

    const filteredNavLinks = navLinks.filter(link => pages.includes(link.title))

    const logout = () => {
        signOut(auth)
        .then(() => navigate('/'))
    }

  return (
    <div className='sidebar'>
        <Link to={'/'} className='sidebar-logo'>
            <img src={logo} alt="" />
        </Link>
        <div className="sidebar-links">
            {
                filteredNavLinks.map((link, i) => (
                    <NavLink key={i} to={link.to} ativeClassName="active">
                        <i className={link.iconClass}></i>
                        {link.title}
                    </NavLink>
                ))
            }
            {/* <NavLink to={'users'} ativeClassName="active">
                <i className="fi fi-rr-users-alt"></i>
                Users
            </NavLink>
            <NavLink to={'payouts'} ativeClassName="active">
                <i className="fi fi-tr-usd-circle"></i>
                Payouts Requests
            </NavLink>
            <NavLink to={'releases'} ativeClassName="active">
                <i className="fi fi-ts-rocket-lunch"></i>
                Releases
            </NavLink>
            <NavLink to={'artists'} ativeClassName="active">
                <i className="fi fi-tr-music-note"></i>
                Artists
            </NavLink>
            <NavLink to={'labels'} ativeClassName="active">
                <i className="fi fi-tr-ticket-alt"></i>
                Labels
            </NavLink>
            <NavLink to={'analytics'} ativeClassName="active">
                <i className="fi fi-tr-stats"></i>
                Analytics
            </NavLink>
            <NavLink to={'reports'} ativeClassName="active">
                <i className="fi fi-tr-money-check-edit"></i>
                Upload Reports
            </NavLink>
            <NavLink to={'reportsHistory'} ativeClassName="active">
                <i className="fi fi-tr-rectangle-vertical-history"></i>
                Reports History
            </NavLink>
            <NavLink to={'platforms'} ativeClassName="active">
                <i className="fi fi-tr-tv-music"></i>
                Platforms
            </NavLink> */}
            {
                userType === 'super-admin' && (
                    <NavLink to={'settings'} ativeClassName="active">
                        <i className="fi fi-tr-gears"></i>
                        Settings
                    </NavLink>
                )
            }
            <Link to={'#'} onClick={logout}>
                <i className="fi fi-tr-leave"></i>
                Logout
            </Link>
        </div>
    </div>
  )
}

export default AdminSidebar