import { child, equalTo, get, onChildAdded, orderByChild, push, query, ref, remove, set, update } from 'firebase/database'
import React, { useEffect, useRef, useState } from 'react'
import { db } from '../../firebase'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'

const AdminSettings = () => {

    const [users, setUsers] = useState([])

    const successBtn = useRef()
    const removeBtn = useRef()
    const roleSuccess = useRef()
    const uid = localStorage.getItem('aid')

    const [roles, setRoles] = useState([])

    useEffect(() => {
        get(child(ref(db, 'users'), uid))
        .then(snapshot => {
            if (snapshot.val().role === 'super-admin') {
                get(ref(db, 'users'))
                .then(snapshot => {
                    const usersData = snapshot.val() || {}; // Get the data from the snapshot
                    const usersArray = Object.entries(usersData).map(([key, value]) => ({
                        id: key, // Include the key as "id" property
                        ...value // Spread the rest of the properties
                    }));
                    setUsers(usersArray.filter(user => user.role === 'admin'))
                })
            }
        })
        get(ref(db, 'roles'))
        .then(snapshot => {
            if (snapshot.exists()) {
                const rolesData = Object.entries(snapshot.val()).map(([key, role]) => {
                    return {
                        key: key,
                        ...role
                    }
                })
                setRoles(rolesData)
                setAdminRole(rolesData[0].key)
                setAdminRoleName(rolesData[0].name)
            }
        })
    }, [])


    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [adminRole, setAdminRole] = useState('')
    const [adminRoleName, setAdminRoleName] = useState('-')
    const [removeKey, setRemoveKey] = useState('')
    const [features, setFeatures] = useState([])
    const [selectRole, setSelectRole] = useState('New Role')
    const [roleName, setRoleName] = useState('')
    const [roleKey, setRoleKey] = useState('')
    const [addBtnText, setAddBtnText] = useState('Submit')
    const [removeBtnText, setRemoveBtnText] = useState('Remove')

    const [error, setError] = useState('')

    const handleError = (errorMsg) => {
        setError(errorMsg)
        setTimeout(() => {
            setError('')
        }, 3000);
    }

    const findUserRole = (role) => {
        const roleExist = roles.find(item => item.key === role)
        return roleExist ? roleExist.name : '-'
    }

    const updateUserRole = (id, role) => {
        let newUsers = [...users]
        newUsers[id].adminRole = role.key
        setUsers(newUsers)
    }

    const saveUsers = () => {
        users.forEach(user => {
            update(child(ref(db, 'users'), user.id), {
                adminRole: user.adminRole
            })
        })
        successBtn.current.click()
    }

    const handleCheck = (feature) => {
        const isCheck = features.some(item => item === feature)
        if (isCheck) {
            setFeatures(prevFeatures => prevFeatures.filter(item => item !== feature))
        } else {
            setFeatures(prevFeatures => [...prevFeatures, feature])
        }
    }

    const handleSelectRole = (role) => {
        setSelectRole(role.name)
        setRoleName(role.name === 'New Role' ? '' : role.name)
        setRoleKey(role.key)
        setFeatures(role.features)
    }

    const submitRole = () => {
        if (roleKey !== '') {
            update(child(ref(db, 'roles'), roleKey), {
                name: roleName,
                features: features
            })
        } else {
            set(push(ref(db, 'roles')), {
                name: roleName,
                features: features
            })
        }
        roleSuccess.current.click()
    }
    
    const removeRole = () => {
        remove(child(ref(db, 'roles'), roleKey))
        roleSuccess.current.click()
    }

    const addAdmin = (e) => {
        e.preventDefault()
        setAddBtnText('Please Wait...')
        axios.post('https://bne-backend.vercel.app/createAdmin', {
            email: email,
            password: password
        })
        .then(res => {
            set(child(ref(db, 'users'), res.data.uid), {
                firstName,
                lastName,
                email,
                role: 'admin',
                adminRole: adminRole
            })
            setAddBtnText('Submit')
            successBtn.current.click()
        })
        .catch(err => {
            setAddBtnText('Submit')
            handleError(err.response.data.error.message)
        })
    }

    const removeAdmin = () => {
        setRemoveBtnText('Please Wait...')
        axios.post('https://bne-backend.vercel.app/removeAdmin', {
            key: removeKey
        })
        .then(() => {
            remove(child(ref(db, 'users'), removeKey))
            setRemoveBtnText('Remove')
            successBtn.current.click()
        })
        .catch(err => setRemoveBtnText('Remove'))
    }

  return (
    <div className="admin-page py-5">
        <div className='container'>
            <h1 className='dash-title'>Settings</h1>
            <div className="row">
                <div className="col-12 mt-4">
                    <div className="dash-card">
                        <div className="dash-card-heading">
                            <h2 className="dash-card-title">Users</h2>
                            <div className="d-flex flex-wrap">
                                <button 
                                    className="dash-card-heading-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#roleModal"
                                >
                                    <i className="fi fi-rr-plus me-2"></i>
                                    Add Role
                                </button>
                                <button 
                                    className={`dash-card-heading-btn ms-2 ${roles.length === 0 && 'disabled'}`}
                                    data-bs-toggle="modal"
                                    data-bs-target="#adminModal"
                                    disabled={roles.length === 0 ? true : false}
                                >
                                    <i className="fi fi-rr-plus me-2"></i>
                                    Add Admin
                                </button>
                            </div>
                        </div>
                        <div className="table-responsive mt-3">
                            <table className="table dash-table">
                                <thead>
                                    <tr>
                                        <td>User</td>
                                        <td>Role</td>
                                        <td className='text-center'>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        users.map((user, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <div className="table-item">
                                                        {user.email}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        <div className="dropdown dash-form-dropdown">
                                                            <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                {findUserRole(user.adminRole)}
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                {
                                                                    roles.map((role, i) => (
                                                                        <li key={i} onClick={() => updateUserRole(index, role)}>{role.name}</li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item d-flex justify-content-center">
                                                        <button 
                                                            className="table-action-btn a-red me-1"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#confirmModal"
                                                            onClick={() => setRemoveKey(user.id)}
                                                        >
                                                            <i className="fi fi-br-cross"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="dash-form-btns">
                                <button 
                                    type="submit"
                                    className={`dash-form-btn ${users.length === 0 ? 'disabled' : ''}`}
                                    disabled={users.length === 0 ? true : false}
                                    onClick={saveUsers}
                                >Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>


        </div>


        <div className="modal fade" id="roleModal" tabindex="-1" aria-labelledby="roleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="artist2ModalLabel">Add Role</h1>
                        <button type="button" className="btn-close" data-bs-toggle="modal" data-bs-target="#editTrackModal"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="dash-form-group">
                                    <label>Select Role</label>
                                    <div className="dropdown dash-form-dropdown">
                                        <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {selectRole}
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li onClick={() => handleSelectRole({name: 'New Role', key: '', features: []})}>New Role</li>
                                            {
                                                roles.map((role, i) => (
                                                    <li key={i} onClick={() => handleSelectRole(role)}>{role.name}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <div className="dash-form-group">
                                    <label>Role Name</label>
                                    <input 
                                        type="text" 
                                        className='dash-form-input' 
                                        onChange={e => setRoleName(e.target.value)} 
                                        value={roleName} 
                                        placeholder='Role Name' 
                                    />
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <div className="dash-form-group">
                                    <label className='mb-2'>Features</label>
                                    <div className="dash-form-check d-flex">
                                        <input 
                                            type="checkbox" 
                                            id='role1' 
                                            className='form-check-input' 
                                            onChange={() => handleCheck('Users')}
                                            checked={features.some(item => item === 'Users')}
                                        />
                                        <label className='ms-2' htmlFor='role1'>Users</label>
                                    </div>
                                    <div className="dash-form-check d-flex">
                                        <input 
                                            type="checkbox" 
                                            id='role2' 
                                            className='form-check-input' 
                                            onChange={() => handleCheck('Payout Requests')}
                                            checked={features.some(item => item === 'Payout Requests')}
                                        />
                                        <label className='ms-2' htmlFor='role2'>Payout Requests</label>
                                    </div>
                                    <div className="dash-form-check d-flex">
                                        <input 
                                            type="checkbox" 
                                            id='role3' 
                                            className='form-check-input' 
                                            onChange={() => handleCheck('Releases')}
                                            checked={features.some(item => item === 'Releases')}
                                        />
                                        <label className='ms-2' htmlFor='role3'>Releases</label>
                                    </div>
                                    <div className="dash-form-check d-flex">
                                        <input 
                                            type="checkbox" 
                                            id='role4' 
                                            className='form-check-input' 
                                            onChange={() => handleCheck('Labels')}
                                            checked={features.some(item => item === 'Labels')}
                                        />
                                        <label className='ms-2' htmlFor='role4'>Labels</label>
                                    </div>
                                    <div className="dash-form-check d-flex">
                                        <input 
                                            type="checkbox" 
                                            id='role5' 
                                            className='form-check-input' 
                                            onChange={() => handleCheck('Artists')}
                                            checked={features.some(item => item === 'Artists')}
                                        />
                                        <label className='ms-2' htmlFor='role5'>Artists</label>
                                    </div>
                                    <div className="dash-form-check d-flex">
                                        <input 
                                            type="checkbox" 
                                            id='role6' 
                                            className='form-check-input' 
                                            onChange={() => handleCheck('Upload Reports')}
                                            checked={features.some(item => item === 'Upload Reports')}
                                        />
                                        <label className='ms-2' htmlFor='role6'>Upload Reports</label>
                                    </div>
                                    <div className="dash-form-check d-flex">
                                        <input 
                                            type="checkbox" 
                                            id='role7' 
                                            className='form-check-input' 
                                            onChange={() => handleCheck('Analytics')}
                                            checked={features.some(item => item === 'Analytics')}
                                        />
                                        <label className='ms-2' htmlFor='role7'>Analytics</label>
                                    </div>
                                    <div className="dash-form-check d-flex">
                                        <input 
                                            type="checkbox" 
                                            id='role8' 
                                            className='form-check-input' 
                                            onChange={() => handleCheck('Reports History')}
                                            checked={features.some(item => item === 'Reports History')}
                                        />
                                        <label className='ms-2' htmlFor='role8'>Reports History</label>
                                    </div>
                                    <div className="dash-form-check d-flex">
                                        <input 
                                            type="checkbox" 
                                            id='role9' 
                                            className='form-check-input' 
                                            onChange={() => handleCheck('Platforms')}
                                            checked={features.some(item => item === 'Platforms')}
                                        />
                                        <label className='ms-2' htmlFor='role9'>Platforms</label>
                                    </div>
                                    <div className="dash-form-check d-flex">
                                        <input 
                                            type="checkbox" 
                                            id='role10' 
                                            className='form-check-input' 
                                            onChange={() => handleCheck('Roles / Genres')}
                                            checked={features.some(item => item === 'Roles / Genres')}
                                        />
                                        <label className='ms-2' htmlFor='role10'>Roles / Genres</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <div className="d-flex justify-content-between flex-wrap">
                                    <button 
                                        className={`dash-form-btn ${selectRole === 'New Role' ? 'disabled' : ''}`}
                                        disabled={selectRole === 'New Role' ? true : false}
                                        onClick={removeRole}
                                    >Delete Role</button>
                                    <div className="dash-form-btns">
                                        <button 
                                            className='dash-form-btn me-2'
                                            data-bs-dismiss="modal"
                                        >Cancel</button>
                                        <button 
                                            className={`dash-form-btn ${roleName === '' || features.length === 0 ? 'disabled' : ''}`}
                                            disabled={roleName === '' || features.length === 0 ? true : false}
                                            onClick={submitRole}
                                        >Done</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button ref={roleSuccess} data-bs-target="#roleSuccessModal" data-bs-toggle="modal" hidden></button>
        </div>

        <div className="modal fade" id="adminModal" tabindex="-1" aria-labelledby="adminModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="artist2ModalLabel">Add Admin</h1>
                        <button type="button" className="btn-close" data-bs-toggle="modal" data-bs-target="#editTrackModal"></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={addAdmin} className="row">
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>First Name</label>
                                    <input 
                                        type="text" 
                                        placeholder='First Name' 
                                        className='dash-form-input' 
                                        onChange={e => setFirstName(e.target.value)}
                                        value={firstName}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Last Name</label>
                                    <input 
                                        type="text" 
                                        placeholder='Last Name' 
                                        className='dash-form-input' 
                                        onChange={e => setLastName(e.target.value)}
                                        value={lastName}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Email</label>
                                    <input 
                                        type="email" 
                                        placeholder='Email' 
                                        className='dash-form-input' 
                                        onChange={e => setEmail(e.target.value)}
                                        value={email}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Password</label>
                                    <input 
                                        type="password" 
                                        placeholder='Password' 
                                        className='dash-form-input' 
                                        onChange={e => setPassword(e.target.value)}
                                        value={password}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Role</label>
                                    <div className="dropdown dash-form-dropdown">
                                        <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {adminRoleName}
                                        </button>
                                        <ul className="dropdown-menu">
                                            {
                                                roles.map((role, i) => (
                                                    <li key={i} onClick={() => {setAdminRole(role.key); setAdminRoleName(role.name)}}>{role.name}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-12 mt-3">
                                {
                                    error !== '' && <h5 className='text-danger text-center mb-3'>{error}</h5>
                                }
                                <div className="dash-form-btns">
                                    <button 
                                        type="submit"
                                        className={`dash-form-btn ${addBtnText === 'Please Wait...' && 'disabled'}`}
                                        disabled={addBtnText === 'Please Wait...' ? true : false}
                                    >{addBtnText}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>


        <button ref={successBtn} data-bs-target="#successModal" data-bs-toggle="modal" hidden></button>
        <div className="modal fade" id="successModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="successModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/admin/settings">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Admins Saved Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>


        <div className="modal fade" id="roleSuccessModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="roleSuccessModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/admin/settings">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Roles Saved Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>


        <div className="modal fade" id="confirmModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="confirmModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <Link className='success-close-btn' to="#" data-bs-dismiss="modal">
                            <i className="fi fi-rr-cross"></i>
                        </Link>
                        <h5>Are you sure you want to remove this user?</h5>
                        <div className="d-flex justify-content-center mt-4">
                            <button className="dash-card-heading-btn cancel-btn share-btn me-2" data-bs-toggle="modal" data-bs-target="#addShareModal">Cancel</button>
                            <button 
                                className={`dash-card-heading-btn share-btn ${removeBtnText === 'Please Wait...' && 'disabled'}`} 
                                onClick={removeAdmin}
                                disabled={removeBtnText === 'Please Wait...' ? true : false}
                            >{removeBtnText}</button>
                            <button ref={removeBtn} data-bs-toggle="modal" data-bs-target="#successModal" hidden></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
  )
}

export default AdminSettings