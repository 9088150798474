import { child, equalTo, get, onChildAdded, orderByChild, query, ref, update } from 'firebase/database'
import React, { useEffect, useRef, useState } from 'react'
import { db, storage } from '../../firebase'
import { getDownloadURL, uploadBytes, ref as sRef } from 'firebase/storage'
import { Link } from 'react-router-dom'
import Pagination from '../Paginations'

const AdminLabels = () => {

    const [labels, setLabels] = useState([])
    const [users, setUsers] = useState([])

    const successBtn = useRef()

    useEffect(() => {
        get(ref(db, 'users'))
        .then(snapshot => {
            const usersData = snapshot.val() || {}; // Get the data from the snapshot
            const usersArray = Object.entries(usersData).map(([key, value]) => ({
                id: key, // Include the key as "id" property
                ...value // Spread the rest of the properties
            }));
            setUsers(usersArray)
            const flattenedData = usersArray.flatMap(user => 
                user.labels !== undefined && user.labels.map((label, i) => ({
                    index: i,
                    key: user.id,
                    email: user.email,
                    name: label.name,
                    country: label.country
                }))
            ).filter(item => item !== false)

            const removeLast = flattenedData.pop()
            setLabels(flattenedData)
        })
    }, [])


    /////////// PAGINATION ///////////
    const itemsPerPage = 25;
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = labels.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(labels.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


  return (
    <div className="admin-page py-5">
        <div className='container'>
            <h1 className='dash-title'>Labels</h1>
            <div className="row">
                <div className="col-12 mt-4">
                    <div className="dash-card">
                        <div className="dash-card-heading">
                            <h2 className="dash-card-title">Labels</h2>
                        </div>
                        <div className="table-responsive mt-3">
                            <table className="table dash-table">
                                <thead>
                                    <tr>
                                        <td>User</td>
                                        <td>Name</td>
                                        <td>Country</td>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentItems.map((label, i) => (
                                            <tr key={i}>
                                                <td>
                                                    <div className="table-item">
                                                        {label.email}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        {label.name}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        {label.country}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item d-flex">
                                                        <Link
                                                            to={`edit/${label.key}/${label.index}`} 
                                                            className="table-action-btn a-blue me-1"
                                                        >
                                                            <i className="fi fi-rr-pencil"></i>
                                                        </Link>
                                                        {/* <button 
                                                            className="table-action-btn a-green"
                                                        >
                                                            <i className="fi fi-br-check"></i>
                                                        </button> */}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        {/* <div className="table-pagination">
                            {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                            <button key={pageNumber} className={currentPage === pageNumber && 'active'} onClick={() => handlePageChange(pageNumber)}>
                                {pageNumber}
                            </button>
                            ))}
                        </div> */}
                        <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
                    </div>
                </div>
            </div>


        </div>


    </div>
  )
}

export default AdminLabels