import React, { useState } from 'react'
import logo from "../assets/logo.svg";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../sass/auth.scss'
import { auth } from '../firebase';
import { updateEmail } from 'firebase/auth';

const UpdateEmail = () => {

    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const location = useLocation();
    const oobCode = new URLSearchParams(location.search).get('oobCode')

    const [error, setError] = useState('')

    const handleError = (errorMsg) => {
        setError(errorMsg)
        setTimeout(() => {
            setError('')
        }, 3000);
    }


    const handleUpdate = (e) => {
        e.preventDefault()
        updateEmail(auth, oobCode, email)
        .then(() => {
            // const user = userCredential.user
            // localStorage.setItem('uid', user.uid)
            console.log('changed')
            // navigate('/login');
        })
        .catch((error) => {
            console.log(error)
            // ..
        });
    }



  return (
    <div className='auth-page'>

        <form className="container py-5" onSubmit={handleUpdate}>
            <div className="row">
                <div className="col-lg-6 auth-container">
                    <Link to={'/'} className='auth-logo'>
                        <img src={logo} alt="" />
                    </Link>

                    <h1>Update Email</h1>
                    <h6>
                        Please enter your new email address
                    </h6>
                    <div className='auth-form'>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className='auth-form-input' placeholder='New Email Address' required />
                    </div>

                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-lg-6'>
                    {
                        error !== '' && <h5 className='text-danger text-center mb-3'>{error}</h5>
                    }
                    <button className='auth-form-btn' type='submit'>
                        <span>Update</span>
                    </button>
                    <h6 className='auth-message'>Forgot Your Password? <Link to={'/resetPassword'}>Reset Password</Link></h6>
                </div>
            </div>
        </form>
        
    </div>
  )
}

export default UpdateEmail