import { child, get, ref, update } from 'firebase/database'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { db, storage } from '../../firebase'
import { getDownloadURL, uploadBytes, ref as sRef } from 'firebase/storage'


const AddLabels = () => {

    const successBtn = useRef()
    const uid = localStorage.getItem('uid')
    
    const [labels, setLabels] = useState([])

    const [name, setName] = useState('')
    const [country, setCountry] = useState('')

    //////////// GET USER'S RELEASES ////////////
    useEffect(() => {
        get(child(ref(db, 'users'), uid))
        .then(snapshot => {
            if (snapshot.val().labels !== undefined) {
                setLabels(snapshot.val().labels); 
            } else {
                //
            }
        })
    }, [])


    const fileInputRef = useRef(null);
    const [imgPreview, setImgPreview] = useState(false)
    const [imgSrc, setImgSrc] = useState('')
    const [uploadLabel, setUploadLabel] = useState('Choose file')

    const uploadArtwork = async (event) => {
        setUploadLabel('Please Wait...')
        const file = event.target.files[0];
        const storageRef = sRef(storage, `labels/${file.name}`);
      
        try {
          // Upload file to Firebase Storage
          await uploadBytes(storageRef, file);
      
          // Get download URL
          const downloadURL = await getDownloadURL(storageRef);
          setImgSrc(downloadURL)
          setImgPreview(true)
          setUploadLabel('Replace file')
        } catch (error) {
          console.error("Error uploading file:", error);
        }
    }      

    const removeImg = () => {
        fileInputRef.current.value = '';
        setImgSrc('')
        setImgPreview(false)
        setUploadLabel('Choose file')
    }

    const handleAddLabel = () => {

        const label = {
            name,
            country,
            logo: imgSrc
        }
        
        const newLabels = [...labels, label]
        update(child(ref(db, 'users'), uid), {
            labels: newLabels
        })
        successBtn.current.click()
    }

  return (
    <div className='dash-section'>
        <h1 className='dash-title'>New Label</h1>
        <div className="row">
            <div className="col-12 mt-4">
                <div className="dash-card">
                    <div className="row">
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Logo</label>
                                <div className="img-file-upload">
                                    {
                                        imgPreview && (
                                            <div className='img-preview'>
                                                <img src={imgSrc} alt="" />
                                                <i className="fi fi-rr-trash remove-artwork" onClick={removeImg}></i>
                                            </div>
                                        )
                                    }
                                    <div className="dash-form-file py-4">
                                        <i className="fi fi-rr-cloud-upload"></i>
                                        {uploadLabel}
                                        <input type="file" ref={fileInputRef} onChange={uploadArtwork} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-3"></div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Name</label>
                                <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder='Name' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Country</label>
                                <input value={country} onChange={(e) => setCountry(e.target.value)} type="text" placeholder='Country' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="dash-form-btns">
                                <button className='dash-form-btn' onClick={handleAddLabel}>Submit</button>
                                <button ref={successBtn} data-bs-toggle="modal" data-bs-target="#successModal" hidden></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div className="modal fade" id="successModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="successModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a href="/dashboard/labels" className='success-close-btn'>
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Label Added Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AddLabels