import React, { useEffect, useRef, useState } from 'react'
import { Link, Route, Routes, useNavigate } from 'react-router-dom'
import { auth, db } from '../firebase'
import { onAuthStateChanged } from 'firebase/auth'
import AdminUsers from '../components/AdminDashboard/AdminUsers'
import AdminSidebar from '../components/AdminDashboard/AdminSidebar'
import AdminPayouts from '../components/AdminDashboard/AdminPayouts'
import AdminReleases from '../components/AdminDashboard/AdminReleases'
import AdminArtists from '../components/AdminDashboard/AdminArtists'
import AdminLabels from '../components/AdminDashboard/AdminLabels'
import AdminEditRelease from '../components/AdminDashboard/AdminEditRelease'
import AdminEditArtist from '../components/AdminDashboard/AdminEditArtist'
import AdminEditLabel from '../components/AdminDashboard/AdminEditLabel'
import AdminEditUser from '../components/AdminDashboard/AdminEditUser'
import AdminReports from '../components/AdminDashboard/AdminReports'
import AdminReportsHistory from '../components/AdminDashboard/AdminReportsHistory'
import AdminReportsDetails from '../components/AdminDashboard/AdminReportsDetails'
import AdminPlatforms from '../components/AdminDashboard/AdminPlatforms'
import { child, get, ref } from 'firebase/database'
import AdminAnalytics from '../components/AdminDashboard/AdminAnalytics'
import AdminSettings from '../components/AdminDashboard/AdminSettings'
import AdminRolesGenres from '../components/AdminDashboard/AdminRolesGenres'

const AdminDashboard = () => {

    const navigate = useNavigate()

    // useEffect(() => {
    //     onAuthStateChanged(auth, (user) => {
    //       if (user) {
    //         //
    //       } else {
    //         // User is signed out
    //         localStorage.removeItem('uid')
    //         navigate('/')
    //       }
    //     });
    // }, [])

    const uid = localStorage.getItem('aid')

    const allPages = [
        'Users',
        'Payout Requests',
        'Roles / Genres',
        'Releases',
        'Artists',
        'Labels',
        'Analytics',
        'Upload Reports',
        'Reports History',
        'Platforms'
    ]    

    const [userType, setUserType] = useState('')
    const [pages, setPages] = useState([])
    const messageBtn = useRef()
    const [upcsMessage, setUpcsMessage] = useState('')

    useEffect(() => {
        get(child(ref(db, 'users'), uid))
        .then(snapshot => {
            setUserType(snapshot.val().role)
            if (snapshot.val().role !== 'admin' && snapshot.val().role !== 'super-admin') {
                navigate('/')
            } else if (snapshot.val().role === 'admin') {
                if (snapshot.val().adminRole) {
                    get(child(ref(db, 'roles'), snapshot.val().adminRole))
                    .then(snapshot => {
                        if (snapshot.exists()) {
                            setPages(snapshot.val().features)
                        } else {
                            setPages(allPages)
                        }
                    })
                } else {
                    setPages(allPages)
                }
            } else {
                setPages(allPages)
            }
        })

        get(ref(db, 'upcs'))
        .then(snapshot => {
            if (snapshot.exists()) {
                const upcData = snapshot.val() || {}; // Get the data from the snapshot
                const upcArray = Object.entries(upcData).map(([key, item]) => ({
                    ...item,
                    index: key
                }));
                if (upcArray.length === 0) {
                    setUpcsMessage('No UPCs Available')
                    messageBtn.current.click()
                } else if (upcArray.length < 10) {
                    setUpcsMessage('Less than 10 UPCs Available')
                    messageBtn.current.click()
                }
            } else {
                setUpcsMessage('No UPCs Available')
                messageBtn.current.click()   
            }
        })
    }, [])

  return (
    <div className='dashboard'>
        <div className="container">
            <div className="d-flex flex-wrap">
                <div className="dash-left-container py-2 px-2 dash-sidebar">
                    <AdminSidebar />
                </div>
                <div className="dash-right-container py-2 px-2">
                    {/* <DashboardHeader /> */}
                    <br />
                    <Routes>
                        {pages.includes('Users') && <Route element={<AdminUsers />} path="users" />}
                        {pages.includes('Users') && <Route element={<AdminEditUser />} path="users/:uid" />}
                        {pages.includes('Payout Requests') && <Route element={<AdminPayouts />} path="payouts" />}
                        {pages.includes('Roles / Genres') && <Route element={<AdminRolesGenres />} path="rolesandgenres" />}
                        {pages.includes('Releases') && <Route element={<AdminReleases />} path="releases" />}
                        {pages.includes('Releases') && <Route element={<AdminEditRelease />} path="releases/edit/:uid/:index" />}
                        {pages.includes('Artists') && <Route element={<AdminArtists />} path="artists" />}
                        {pages.includes('Artists') && <Route element={<AdminEditArtist />} path="artists/edit/:uid/:index" />}
                        {pages.includes('Labels') && <Route element={<AdminLabels />} path="labels" />}
                        {pages.includes('Analytics') && <Route element={<AdminAnalytics />} path="analytics" />}
                        {pages.includes('Labels') && <Route element={<AdminEditLabel />} path="labels/edit/:uid/:index" />}
                        {pages.includes('Upload Reports') && <Route element={<AdminReports />} path="reports" />}
                        {pages.includes('Reports History') && <Route element={<AdminReportsHistory />} path="reportsHistory" />}
                        {pages.includes('Reports History') && <Route element={<AdminReportsDetails />} path="reportsHistory/details" />}
                        {pages.includes('Platforms') && <Route element={<AdminPlatforms />} path="platforms" />}
                        {userType === 'super-admin' && <Route element={<AdminSettings />} path="settings" />}
                    </Routes>
                </div>
            </div>
        </div>

        <button ref={messageBtn} data-bs-toggle="modal" data-bs-target="#upcsModal" hidden></button>
        <div className="modal fade" id="upcsModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="upcsModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <Link to={'#'} className='success-close-btn' data-bs-dismiss="modal">
                            <i className="fi fi-rr-cross"></i>
                        </Link>
                        <i className="fi fi-rr-triangle-warning text-danger success-icon"></i>
                        <h5>{upcsMessage}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AdminDashboard