import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { db } from '../../firebase';
import { child, equalTo, get, orderByChild, query, ref, update } from 'firebase/database';
import Pagination from '../Paginations';

const AddSplits = () => {

    const uid = localStorage.getItem('uid')
    const [track, setTrack] = useState({})
    const [splits, setSplits] = useState([])
    const [recoupEnable, setRecoupEnable] = useState(true)

    const closeRef = useRef()
    const { id } = useParams()

    const getTotalSharesById = (dataArray) => {
        // Filter the array to include only objects with the specified id
        const filteredShares = dataArray.filter((item) => item.track === id);
      
        // Calculate the total shares for the specified id
        const totalShares = filteredShares.reduce((sum, item) => sum + item.share * 1, 0);
      
        return totalShares;
    };

    useEffect(() => {
        get(child(ref(db, 'users'), uid))
        .then(snapshot => {
            if (snapshot.val().releases !== undefined) {
                const liveReleases = snapshot.val().releases.filter(item => item.status === 'Distributed')
                const listedTracks = liveReleases.flatMap((release) => release.tracks)
                const splitTrack = listedTracks.find((item) => item.isrc === id)
                setTrack(splitTrack)

                
                if (snapshot.val().splits !== undefined) {
                    setSplits(snapshot.val().splits)
                    const trackSplits = snapshot.val().splits.filter(item => item.track === id)
                    const hasRecoupment = trackSplits.find(item => item.recoupment * 1 > 0)
                    if (hasRecoupment) {
                        setRecoupEnable(false)
                    } else {
                        setRecoupEnable(true)
                    }
                }
            } else {
                //
            }
        })
    }, [])


    const [error, setError] = useState('')

    const handleError = (errorMsg) => {
        setError(errorMsg)
        setTimeout(() => {
            setError('')
        }, 3000);
    }

    const successBtn = useRef()
    const [email, setEmail] = useState('')
    const [share, setShare] = useState('')
    const [recoupment, setRecoupment] = useState(0)

    const addShare = () => {

        const totalShares = getTotalSharesById(splits) + share * 1
        if (totalShares <= 100) {
            const newSplit = {
                user: email,
                share: share,
                recoupment: recoupment,
                track: id
            }
            const newSplits = [...splits, newSplit]
            update(child(ref(db, 'users'), uid), {
                splits: newSplits
            })
            successBtn.current.click()
        } else {
            handleError('The total sum of shares cannot exceed 100')
        }
        
    }

    const removeShare = (index) => {
        const newSplits = [...splits]
        newSplits.splice(index, 1)
        setSplits(newSplits)
        const trackSplits = newSplits.filter(item => item.track === id)
        const hasRecoupment = trackSplits.find(item => item.recoupment * 1 > 0)
        if (hasRecoupment) {
            setRecoupEnable(false)
        } else {
            setRecoupEnable(true)
        }
        setRecoupment(0)
        update(child(ref(db, 'users'), uid), {
            splits: newSplits
        })
    }

    /////////// PAGINATION ///////////
    const itemsPerPage = 25;
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = splits.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(splits.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


  return (
    <div className='dash-section'>
        <h1 className='dash-title'>Splits</h1>
        <div className="row">
            <div className="col-12 mt-4">
                <div className="dash-card">
                    <div className="dash-card-heading">
                        <h2 className="dash-card-title">
                            <Link to={'/dashboard/splits'} className='title-back-link'>
                                <i className="fi fi-rr-arrow-small-left"></i>
                            </Link>
                            {track.title}
                        </h2>
                        <button data-bs-toggle="modal" data-bs-target="#shareModal" className="dash-card-heading-btn">
                            <i className="fi fi-sr-plus"></i>
                            New User
                        </button>
                    </div>
                    <div className="table-responsive mt-3">
                        <table className="table dash-table">
                            <thead>
                                <tr>
                                    <td>User</td>
                                    <td>Share</td>
                                    <td>Recoupment</td>
                                    <td>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    currentItems.map((split, i) => (
                                        split.track === id && (
                                            <tr key={i}>
                                                <td>
                                                    <div className="table-item">
                                                        {split.user}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        {split.share}%
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        ${(split.recoupment * 1).toFixed(2)}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        <i 
                                                            onClick={() => removeShare(i)}
                                                            className="fi fi-rr-trash table-action-btn a-red"
                                                        ></i>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    {/* <div className="table-pagination">
                        {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                        <button key={pageNumber} className={currentPage === pageNumber && 'active'} onClick={() => handlePageChange(pageNumber)}>
                            {pageNumber}
                        </button>
                        ))}
                    </div> */}
                    <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
                </div>
            </div>
        </div>

        <div className="modal fade" id="shareModal" tabindex="-1" aria-labelledby="shareModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="shareModalLabel">Add Share</h1>
                        <button type="button" className="btn-close" data-bs-toggle="modal"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className={`col-md-${recoupEnable ? 6 : 4} mt-3`}>
                                <div className="dash-form-group">
                                    <label>Share (%)</label>
                                    <input value={share} onChange={(e) => setShare(e.target.value)} type="number" placeholder='Share' className='dash-form-input' />
                                </div>
                            </div>
                            {
                                recoupEnable && (
                                    <div className="col-md-6 mt-3">
                                        <div className="dash-form-group">
                                            <label>Recoupment ($)</label>
                                            <input value={recoupment} onChange={(e) => setRecoupment(e.target.value)} type="number" placeholder='Recoupment' className='dash-form-input' />
                                        </div>
                                    </div>
                                )
                            }
                            <div className={`col-md-${recoupEnable ? 12 : 8} mt-3`}>
                                <div className="dash-form-group">
                                    <label>User email</label>
                                    <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" placeholder='Email' className='dash-form-input' />
                                </div>
                            </div>
                            {
                                error !== '' && <h6 className='text-danger text-center mt-3'>{error}</h6>
                            }
                            <div className="col-12 mt-3">
                                <div className="dash-form-btns">
                                    <button 
                                        className='dash-form-btn' 
                                        onClick={addShare}
                                    >Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <button data-bs-toggle="modal" data-bs-target="#successModal" ref={successBtn} hidden></button>
        <div className="modal fade" id="successModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="successModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="#" onClick={() => window.location.reload()}>
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Splits Updated Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>

        {/* <div className="modal fade" id="notesModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="notesModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="modal-top mb-4">
                            <h5>Error</h5>
                            <i ref={closeRef} className="fi fi-rr-cross" data-bs-dismiss="modal"></i>
                        </div>
                        <p>{errorNotes}</p>
                        <div className="d-flex justify-content-end">
                            <Link to={`/dashboard/releases/edit/${editIndex}`} onClick={() => closeRef.current.click()} className='dash-form-btn'>Edit Release</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}

    </div>
  )
}

export default AddSplits