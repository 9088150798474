import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD7Dj4tw5mylK7MOzn95yxrKwyYTzQRBu8",
  authDomain: "bne-studio-prod.firebaseapp.com",
  databaseURL: "https://bne-studio-prod-default-rtdb.firebaseio.com",
  projectId: "bne-studio-prod",
  storageBucket: "bne-studio-prod.appspot.com",
  messagingSenderId: "525414989983",
  appId: "1:525414989983:web:1ea43363ddba694660a2aa",
  measurementId: "G-DVVESZFX8S"
};

const firebaseConfig_db = {
  apiKey: "AIzaSyCWpndJd7uUdMhXNyHUb1awREl1UTUBBXU",
  authDomain: "bne-prod-db.firebaseapp.com",
  databaseURL: "https://bne-prod-db-default-rtdb.firebaseio.com",
  projectId: "bne-prod-db",
  storageBucket: "bne-prod-db.appspot.com",
  messagingSenderId: "1095990450295",
  appId: "1:1095990450295:web:ffb47d22e1e60b8fa48e72",
  measurementId: "G-YG1E5ZERJ2"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const database = getDatabase(app)
export const auth = getAuth(app)
export const storage = getStorage(app)

const app_db = initializeApp(firebaseConfig_db, 'app_db');
export const db = getDatabase(app_db)