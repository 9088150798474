import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Line } from 'react-chartjs-2'
import { Chart as ChartJS } from 'chart.js/auto'
import { child, get, ref, set, update } from 'firebase/database'
import { db } from '../../firebase'
import { generate } from 'random-words'
import axios from 'axios'

const Teams = () => {

    const uid = localStorage.getItem('uid')
    const mid = localStorage.getItem('mid')

    const teamsBtn = useRef()
    const closeBtn = useRef()
    const navigate = useNavigate()

    const [users, setUsers] = useState([])
    const [teams, setTeams] = useState([])
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')

    useEffect(() => {
        teamsBtn.current.click()
        get(ref(db, 'users'))
        .then(snapshot => {
            if (snapshot.exists()) {
                const usersArray = Object.entries(snapshot.val()).map(([key, value]) => {
                    return {
                        id: key,
                        ...value
                    }
                })
                setUsers(usersArray)

                const currentUser = usersArray.find(user => user.id === mid)
                if (currentUser.role === 'user') {
                    setName(currentUser.firstName + ' ' + currentUser.lastName)
                    setEmail(currentUser.email || '')
                }
                const userTeams = currentUser.teams || []
                if (userTeams.length > 0) {
                    userTeams.forEach(team => {
                        const currentTeam = usersArray.find(user => user.id === team)
                        setTeams(prevTeams => [...prevTeams, currentTeam])
                    })
                }
            }
        })
    }, [])


    

  return (
    <div className='dash-section'>
        {/* <h1 className='dash-title'>Teams</h1>
        <div className="dash-card mt-5">
            <div className="dash-card-heading">
                <h2 className="dash-card-title">Teams</h2>
            </div>
            <div className="table-responsive mt-3">
                <table className="table dash-table wide-table">
                    <thead>
                        <tr>
                            <td>Name</td>
                            <td>Email</td>
                            <td>Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            email !== '' && (
                                <tr>
                                    <td>
                                        <div className="table-item">
                                            {name} <span>(Me)</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="table-item">
                                            {email}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="table-item d-flex">
                                            <button 
                                                className="table-action-btn a-blue me-1"
                                                onClick={() => {
                                                    localStorage.setItem('uid', mid)
                                                    navigate('/dashboard/panel')
                                                }}
                                            >
                                                <i className="fi fi-rr-eye"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }
                        {
                            teams.map((user, i) => (
                                <tr key={i}>
                                    <td>
                                        <div className="table-item">
                                            {user.firstName} {user.lastName}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="table-item">
                                            {user.email}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="table-item d-flex">
                                            <button 
                                                className="table-action-btn a-blue me-1"
                                                onClick={() => {
                                                    localStorage.setItem('uid', user.id)
                                                    navigate('/dashboard/panel')
                                                }}
                                            >
                                                <i className="fi fi-rr-eye"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div> */}


        {/* TEAMS */}
        <button ref={teamsBtn} data-bs-target="#TeamsModal" data-bs-toggle="modal" hidden></button>

        <div className="modal fade" id="TeamsModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="TeamsModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                      <div className="modal-top">
                          <h5>Teams</h5>
                          <i className="fi fi-rr-cross" data-bs-dismiss="modal" onClick={() => window.history.back()}></i>
                      </div>
                  </div>
                  <div className="modal-body">
                    <div className="teams-items">
                        {
                            email !== '' && (
                                <div 
                                    className="teams-item"
                                    onClick={() => {
                                        localStorage.setItem('uid', mid)
                                        closeBtn.current.click()
                                        navigate('/dashboard/panel')
                                    }}
                                >
                                    <div className="teams-item-text">
                                        <h5>{name} <span>(Me)</span></h5>
                                        <h6>{email}</h6>
                                    </div>
                                    {mid === uid && <i className="fi fi-rr-check-circle text-success"></i>}
                                </div>
                            )
                        }
                        {
                            teams.map((team, i) => (
                                <div 
                                    className="teams-item" 
                                    key={i}
                                    onClick={() => {
                                        localStorage.setItem('uid', team.id)
                                        closeBtn.current.click()
                                        navigate('/dashboard/panel')
                                    }}
                                >
                                    <div className="teams-item-text">
                                        <h5>{team.firstName} {team.lastName}</h5>
                                        <h6>{team.email}</h6>
                                    </div>
                                    {uid === team.id && <i className="fi fi-rr-check-circle text-success"></i>}
                                </div>
                            ))
                        }
                    </div>
                  </div>
                  <button data-bs-dismiss="modal" ref={closeBtn} hidden></button>
                </div>
            </div>
        </div>

    </div>
  )
}

export default Teams