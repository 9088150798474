import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import countries from '../../arrays/countries.json'
import languages from '../../arrays/languages.json'
import genres from '../../arrays/genres.json'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { auth, db, storage } from '../../firebase'
import { set, push, ref, get, child, update, remove } from 'firebase/database'
import { getDownloadURL, uploadBytes, ref as sRef } from 'firebase/storage'
import { EmailAuthProvider, createUserWithEmailAndPassword, deleteUser, reauthenticateWithCredential, signInWithEmailAndPassword, signOut, updateCurrentUser, updateEmail, updateProfile, verifyBeforeUpdateEmail } from 'firebase/auth'
import { generate } from 'random-words'
import axios from 'axios'


const Profile = () => {


    const uid = localStorage.getItem('uid')
    const mid = localStorage.getItem('mid')


    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [street1, setStreet1] = useState('')
    const [street2, setStreet2] = useState('')
    const [country, setCountry] = useState('')
    const [state, setState] = useState('')
    const [city, setCity] = useState('')
    const [zip, setZip] = useState('')
    const [role, setRole] = useState('')

    const [users, setUsers] = useState([])
    const [members, setMembers] = useState([])

    const successBtn = useRef()

    //////////// GET USER'S RELEASES ////////////
    useEffect(() => {
        get(child(ref(db, 'users'), uid))
        .then(snapshot => {
            initialValues(snapshot.val())
        })

        get(ref(db, 'users'))
        .then(snapshot => {
            if (snapshot.exists()) {
                const usersArray = Object.entries(snapshot.val()).map(([key, value]) => {
                    return {
                        id: key,
                        ...value
                    }
                })
                setUsers(usersArray)

                const currentUser = usersArray.find(user => user.id === uid)
                setRole(currentUser.role || '')
                const userMembers = currentUser.members || []
                if (userMembers.length > 0) {
                    userMembers.forEach(member => {
                        const currentMember = usersArray.find(user => user.id === member)
                        setMembers(prevMembers => [...prevMembers, currentMember])
                    })
                }
            }
        })
    }, [])

    const initialValues = (item) => {
        setFirstName(item.firstName || '')
        setLastName(item.lastName || '')
        setStreet1(item.street1 || '')
        setStreet2(item.street2 || '')
        setCountry(item.country || '')
        setState(item.state || '')
        setCity(item.city || '')
        setZip(item.zip || '')
    }

    
    const editUser = () => {
        
        update(child(ref(db, 'users'), uid), {
            firstName,
            lastName,
            street1,
            street2,
            country,
            state,
            city,
            zip
        })
        successBtn.current.click()
    }


    const user = auth.currentUser;

    const emailSuccessBtn = useRef()

    const [currentEmail, setCurrentEmail] = useState('')
    const [password, setPassword] = useState('')
    const [newEmail, setNewEmail] = useState('')
    const [emailBtn, setEmailBtn] = useState('Submit')

    const [error, setError] = useState('')

    const handleError = (errorMsg) => {
        setError(errorMsg)
        setTimeout(() => {
            setError('')
        }, 3000);
    }
    
    const editEmail = () => {
        setEmailBtn('Please Wait...')

        const credential = EmailAuthProvider.credential(currentEmail, password);
        reauthenticateWithCredential(user, credential)
        .then(() => {
            get(child(ref(db, 'users'), uid))
            .then((snapshot) => {
                const userData = snapshot.val()
                userData.email = newEmail
                get(ref(db, 'users'))
                .then(snapshot => {
                    const usersData = snapshot.val() || {}; // Get the data from the snapshot
                    const usersArray = Object.entries(usersData).map(([key, value]) => ({
                        id: key, // Include the key as "id" property
                        ...value // Spread the rest of the properties
                    }));
                    if (usersArray.some(obj => obj.email === newEmail)) {
                        handleError('Email already exists.')
                        setEmailBtn('Submit')
                    } else {
                        deleteUser(user)
                        .then(() => {
                            createUserWithEmailAndPassword(auth, newEmail, password)
                            .then((userCredential) => {
                                // Signed in 
                                const user = userCredential.user;
                                set(child(ref(db, 'users'), user.uid), userData)
                                localStorage.setItem('uid', user.uid)
                                remove(child(ref(db, 'users'), uid))
                                console.log('Updated')
                                emailSuccessBtn.current.click()
                            })
                        })
                    }
                })
            })
        })
        .catch((err) => {
            const errorCode = err.code;
            console.log(errorCode)
            errorCode == 'auth/invalid-login-credentials' && handleError('Invalid Login Credentials')
            setEmailBtn('Submit')
        })
    }



    /////////// MEMBERS ///////////
    const memberSuccessBtn = useRef()
    const removeBtn = useRef()
    const [email, setEmail] = useState('')
    const [addBtnText, setAddBtnText] = useState('Submit')
    const [removeBtnText, setRemoveBtnText] = useState('Yes')
    const [removeMemberId, setRemoveMemberId] = useState('')

    const passwordGenerator = () => {
        const randomNumber = Math.floor(Math.random() * 9000) + 1000;
        const word = `${generate()}-${generate()}-${randomNumber}`
        const randomPassword = word.slice(0, 1).toUpperCase().concat(word.slice(1))
        return randomPassword
    }

    const addMember = (e) => {
        e.preventDefault()
        setAddBtnText('Please Wait...')
        const addressExistInMembers = members.find(member => member.email === email)
        if (addressExistInMembers) {
            handleError('Member Already Exists')
            setAddBtnText('Submit')
        } else {
            const addressExist = users.find(user => user.email === email)
            const newMembers = members.map(member => member.id)
            if (addressExist) {
                const memberTeams = addressExist.teams || []
                memberTeams.push(uid)
                update(child(ref(db, 'users'), addressExist.id), {
                    teams: memberTeams
                })
                newMembers.push(addressExist.id)
                update(child(ref(db, 'users'), uid), {
                    members: newMembers
                })
                setAddBtnText('Submit')
                memberSuccessBtn.current.click()
            } else {
                const generatedPassword = passwordGenerator()
                axios.post('https://bne-backend.vercel.app/createAdmin', {
                    email: email,
                    password: generatedPassword
                })
                .then(res => {
                    set(child(ref(db, 'users'), res.data.uid), {
                        firstName: 'Invited',
                        lastName: 'User',
                        email,
                        teams: [uid],
                        role: 'member',
                    })
                    newMembers.push(res.data.uid)
                    update(child(ref(db, 'users'), uid), {
                        members: newMembers
                    })
                    setAddBtnText('Submit')
                    memberSuccessBtn.current.click()
                    console.log(generatedPassword)
                })
                .catch(err => {
                    setAddBtnText('Submit')
                    handleError(err.response.data.error.message)
                })
            }
        }
    }


    const removeMember = () => {
        const member = members.find(item => item.id === removeMemberId)
        const user = users.find(item => item.id === mid)
        const memberTeams = member.teams.filter(team => team !== mid)
        const userMembers = user.members.filter(item => item !== removeMemberId)
        update(child(ref(db, 'users'), removeMemberId), {
            teams: memberTeams
        })
        update(child(ref(db, 'users'), mid), {
            members: userMembers
        })
        removeBtn.current.click()
    }


  return (
    <div className='dash-section'>
        <h1 className='dash-title'>Profile</h1>
        <div className="row">
            <div className="col-12 mt-4">
                <div className="dash-card">
                    <div className="row">
                        <div className="col-12">
                            <h4 className='dash-form-heading'>Edit Info</h4>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>First Name</label>
                                <input value={firstName} onChange={(e) => setFirstName(e.target.value)} type="text" placeholder='First Name' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Last Name</label>
                                <input value={lastName} onChange={(e) => setLastName(e.target.value)} type="text" placeholder='Last Name' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Street 1</label>
                                <input value={street1} onChange={(e) => setStreet1(e.target.value)} type="text" placeholder='Street 1' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Street 2</label>
                                <input value={street2} onChange={(e) => setStreet2(e.target.value)} type="text" placeholder='Street 2' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Country</label>
                                <input value={country} onChange={(e) => setCountry(e.target.value)} type="text" placeholder='Country' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>State</label>
                                <input value={state} onChange={(e) => setState(e.target.value)} type="text" placeholder='State' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>City</label>
                                <input value={city} onChange={(e) => setCity(e.target.value)} type="text" placeholder='City' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>ZIP</label>
                                <input value={zip} onChange={(e) => setZip(e.target.value)} type="text" placeholder='ZIP' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="d-flex">
                                <button 
                                    className='dash-form-btn me-2 mt-2' 
                                    data-bs-toggle="modal"
                                    data-bs-target="#emailModal"
                                >Update Email Address</button>
                                <Link 
                                    to={'/resetPassword'}
                                    className='dash-form-btn mt-2' 
                                >Change Password</Link>
                            </div>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="dash-form-btns">
                                <button 
                                    className='dash-form-btn' 
                                    onClick={editUser}
                                >Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                role === 'user' && uid === mid && (
                    <div className="col-12 mt-4">
                        <div className="dash-card">
                            <div className="dash-card-heading">
                                <h2 className="dash-card-title">Members</h2>
                                <button 
                                    data-bs-toggle="modal"
                                    data-bs-target="#addMemberModal"
                                    className="dash-card-heading-btn"
                                >
                                    <i className="fi fi-sr-plus"></i>
                                    Add Member
                                </button>
                            </div>
                            <div className="table-responsive mt-3">
                                <table className="table dash-table wide-table">
                                    <thead>
                                        <tr>
                                            <td>Name</td>
                                            <td>Email</td>
                                            <td>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            members.map((user, i) => (
                                                <tr key={i}>
                                                    <td>
                                                        <div className="table-item">
                                                            {user.firstName} {user.lastName}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="table-item">
                                                            {user.email}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="table-item d-flex">
                                                            <button 
                                                                className="table-action-btn a-red me-1"
                                                                data-bs-target="#confirmModal"
                                                                data-bs-toggle="modal"
                                                                onClick={() => setRemoveMemberId(user.id)}
                                                            >
                                                                <i className="fi fi-rr-trash"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>


        <div className="modal fade" id="emailModal" tabindex="-1" aria-labelledby="emailModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal pb-3">
                        <Link className='success-close-btn' to={'#'} data-bs-dismiss='modal'>
                            <i className="fi fi-rr-cross"></i>
                        </Link>
                        <div className="dash-form-group text-start mt-3">
                            <label>Current Email Address</label>
                            <input value={currentEmail} onChange={(e) => setCurrentEmail(e.target.value)} type="text" placeholder='Current Email Address' className='dash-form-input' />
                        </div>
                        <div className="dash-form-group text-start mt-3">
                            <label>Password</label>
                            <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder='Password' className='dash-form-input' />
                        </div>
                        <div className="dash-form-group text-start mt-3">
                            <label>New Email Address</label>
                            <input value={newEmail} onChange={(e) => setNewEmail(e.target.value)} type="text" placeholder='New Email Address' className='dash-form-input' />
                        </div>
                        {
                            error !== '' && <h5 className='text-danger text-center mb-3'>{error}</h5>
                        }
                        <div className="dash-form-btns mt-3">
                            <button 
                                className='dash-form-btn' 
                                onClick={editEmail}
                            >{emailBtn}</button>
                        </div>
                        <button ref={emailSuccessBtn} data-bs-target="#emailSuccessModal" data-bs-backdrop="static" data-bs-toggle="modal" hidden></button>
                    </div>
                </div>
            </div>
        </div>
        
        <button ref={successBtn} data-bs-target="#successModal" data-bs-backdrop="static" data-bs-toggle="modal" hidden></button>
        <div className="modal fade" id="successModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="successModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/dashboard/profile">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Profile Updated Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="emailSuccessModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="emailSuccessModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/dashboard/profile">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Email Updated Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>


        <div className="modal fade" id="addMemberModal" tabindex="-1" aria-labelledby="addMemberModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="artist2ModalLabel">Add Member</h1>
                        <button type="button" className="btn-close" data-bs-toggle="modal" data-bs-target="#editTrackModal"></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={addMember} className="row">
                            <div className="col-12 mt-3">
                                <div className="dash-form-group">
                                    <label>Email</label>
                                    <input 
                                        type="email" 
                                        placeholder='Email' 
                                        className='dash-form-input' 
                                        onChange={e => setEmail(e.target.value)}
                                        value={email}
                                        required
                                    />
                                </div>
                            </div>
                            
                            <div className="col-12 mt-3">
                                {
                                    error !== '' && <h5 className='text-danger text-center mb-3'>{error}</h5>
                                }
                                <div className="dash-form-btns">
                                    <button 
                                        type="submit"
                                        className={`dash-form-btn ${addBtnText === 'Please Wait...' && 'disabled'}`}
                                        disabled={addBtnText === 'Please Wait...' ? true : false}
                                    >{addBtnText}</button>
                                </div>
                            </div>
                        </form>
                        <button ref={memberSuccessBtn} data-bs-target="#memberSuccessModal" data-bs-toggle="modal" hidden></button>
                    </div>
                </div>
            </div>
        </div>


        <div className="modal fade" id="confirmModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="confirmModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <Link className='success-close-btn' to="#" data-bs-dismiss="modal">
                            <i className="fi fi-rr-cross"></i>
                        </Link>
                        <h5>Are you sure you want to remove this member?</h5>
                        <div className="d-flex justify-content-center mt-4">
                            <button className="dash-card-heading-btn cancel-btn share-btn me-2" data-bs-toggle="modal" data-bs-target="#addShareModal">Cancel</button>
                            <button 
                                className={`dash-card-heading-btn share-btn ${removeBtnText === 'Please Wait...' && 'disabled'}`} 
                                onClick={removeMember}
                                disabled={removeBtnText === 'Please Wait...' ? true : false}
                            >{removeBtnText}</button>
                            <button ref={removeBtn} data-bs-toggle="modal" data-bs-target="#memberSuccessModal" hidden></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className="modal fade" id="memberSuccessModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="memberSuccessModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/dashboard/profile">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Members Saved Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Profile