const Pagination = ({ currentPage, totalPages, handlePageChange }) => {
    const maxPageButtons = 5; // Maximum number of page buttons to display
    const pageButtons = [];

    // Display first page button
    pageButtons.push(
        <button key={1} className={currentPage === 1 ? 'active' : ''} onClick={() => handlePageChange(1)}>
            1
        </button>
    );

    if (totalPages === 1 || totalPages === 0) {
        return null; // Don't render pagination buttons if there is only one page
    }

    // Display ellipsis if there are pages before the current page
    if (currentPage > maxPageButtons) {
        pageButtons.push(<span key="ellipsis-start">...</span>);
    }

    // Calculate the range of page buttons to display around the current page
    const startPage = Math.max(2, currentPage - Math.floor(maxPageButtons / 2));
    const endPage = Math.min(totalPages - 1, startPage + maxPageButtons - 2);

    // Display page buttons in the range
    for (let i = startPage; i <= endPage; i++) {
        pageButtons.push(
            <button key={i} className={currentPage === i ? 'active' : ''} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }

    // Display ellipsis if there are pages after the current page
    if (endPage < totalPages - 1) {
        pageButtons.push(<span key="ellipsis-end">...</span>);
    }

    // Display last page button
    pageButtons.push(
        <button key={totalPages} className={currentPage === totalPages ? 'active' : ''} onClick={() => handlePageChange(totalPages)}>
            {totalPages}
        </button>
    );

    return <div className="table-pagination">{pageButtons}</div>;
};

export default Pagination