import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import countries from '../../arrays/countries.json'
import languages from '../../arrays/languages.json'
import genres from '../../arrays/genres.json'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { db, storage } from '../../firebase'
import { set, push, ref, get, child, update } from 'firebase/database'
import { getDownloadURL, uploadBytes, ref as sRef } from 'firebase/storage'


const AdminEditLabel = () => {





    //////////////////////////////////////////////
    ////////////////// BACKEND ///////////////////
    //////////////////////////////////////////////

    const { index, uid } = useParams()

    const [labels, setLabels] = useState([])

    const [name, setName] = useState('')
    const [country, setCountry] = useState('')
    const [editUserKey, setEditUserKey] = useState('')
    const [editLabelIndex, setEditLabelIndex] = useState('')

    const successBtn = useRef()

    //////////// GET USER'S RELEASES ////////////
    useEffect(() => {
        get(child(ref(db, 'users'), uid))
        .then(snapshot => {
            setLabels(snapshot.val().labels)
            initialValues(snapshot.val().labels[index])
        })
    }, [])

    const initialValues = (item) => {
        setName(item.name)
        setCountry(item.country)
        setImgSrc(item.logo)
        item.logo && setImgPreview(true)
        item.logo && setUploadLabel('Replace file')
    }


    const fileInputRef = useRef(null);
    const [imgPreview, setImgPreview] = useState(false)
    const [imgSrc, setImgSrc] = useState('')
    const [uploadLabel, setUploadLabel] = useState('Choose file')

    const uploadArtwork = async (event) => {
        setUploadLabel('Please Wait...')
        const file = event.target.files[0];
        const storageRef = sRef(storage, `releases/${file.name}`);
      
        try {
          // Upload file to Firebase Storage
          await uploadBytes(storageRef, file);
      
          // Get download URL
          const downloadURL = await getDownloadURL(storageRef);
          setImgSrc(downloadURL)
          setImgPreview(true)
          setUploadLabel('Replace file')
        } catch (error) {
          console.error("Error uploading file:", error);
        }
    }      

    const removeImg = () => {
        fileInputRef.current.value = '';
        setImgSrc('')
        setImgPreview(false)
        setUploadLabel('Choose file')
    }

    
    const editLabel = () => {
        // var releaseKey = Math.random().toString(36).substr(2, 9);
        const label = {
            name,
            country,
            logo: imgSrc
        }

        
        // set(child(ref(db, 'releases'), releaseKey), release)
        const newLabels = [...labels]
        newLabels[index] = label
        update(child(ref(db, 'users'), uid), {
            labels: newLabels
        })
        successBtn.current.click()
    }


  return (
    <div className='dash-section'>
        <h1 className='dash-title'>Edit Label</h1>
        <div className="row">
            <div className="col-12 mt-4">
                <div className="dash-card">
                    <div className="row">
                        <div className="col-12">
                            <h4 className='dash-card-title'>
                                <Link to={'/admin/labels'} className='title-back-link'>
                                    <i className="fi fi-rr-arrow-small-left"></i>
                                </Link>
                                Edit Label
                            </h4>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Logo</label>
                                <div className="img-file-upload">
                                    {
                                        imgPreview && (
                                            <div className='img-preview'>
                                                <img src={imgSrc} alt="" />
                                                <i className="fi fi-rr-trash remove-artwork" onClick={removeImg}></i>
                                            </div>
                                        )
                                    }
                                    <div className="dash-form-file">
                                        <i className="fi fi-rr-cloud-upload"></i>
                                        {uploadLabel}
                                        <input type="file" ref={fileInputRef} onChange={uploadArtwork} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Name</label>
                                <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder='Name' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Country</label>
                                <input value={country} onChange={(e) => setCountry(e.target.value)} type="text" placeholder='Country' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="dash-form-btns">
                                <button 
                                    className='dash-form-btn' 
                                    onClick={editLabel}
                                >Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        
        <button ref={successBtn} data-bs-target="#successModal" data-bs-toggle="modal" hidden></button>
        <div className="modal fade" id="successModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="successModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/admin/labels">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Label Updated Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AdminEditLabel