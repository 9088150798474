import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import amazon from '../../assets/amazon.png'
import spotify from '../../assets/spotify.png'
import apple from '../../assets/apple.png'
import { child, get, ref, update } from 'firebase/database'
import { db } from '../../firebase'

const ReleaseDetails = () => {

    const { id } = useParams();
    const uid = localStorage.getItem('uid')
    const successBtn = useRef()
    const [releases, setReleases] = useState([])
    const [release, setRelease] = useState({})

    const [platforms, setPlatforms] = useState([])
    const [checkedAll, setCheckedAll] = useState(false)

    const validPlatforms = platforms.filter(item => item.status !== 'Taken Down' && item.link !== '')

    useEffect(() => {
        get(child(ref(db, 'users'), uid))
        .then(snapshot => {
            setReleases(snapshot.val().releases)
            setRelease(snapshot.val().releases[id])
            getPlatforms(snapshot.val().releases[id].platforms || [])
        })
    }, [])
    
    const getPlatforms = (releasePlatforms) => {
        get(ref(db, 'platforms'))
        .then(snapshot => {
            const platformsData = snapshot.val() || {}; // Get the data from the snapshot
            const platformsArray = Object.entries(platformsData).map(([key, value]) => ({
                id: key, // Include the key as "id" property
                ...value // Spread the rest of the properties
            }));
            platformsArray.forEach(platform => {
                const platformExist = releasePlatforms.find(item => item.id === platform.id)
                if (platformExist) {
                    setPlatforms(prevPlatforms => [...prevPlatforms, {
                        title: platform.title,
                        logo: platform.logo,
                        ...platformExist
                    }])
                } else {
                    setPlatforms(prevPlatforms => [...prevPlatforms, {
                        id: platform.id,
                        title: platform.title,
                        logo: platform.logo,
                        checked: false,
                        status: 'Never Distributed',
                        link: ''
                    }])
                }
            })
        })
    }

    const statusClasses = {
        'Never Distributed': 's-orange',
        'Distributed': 's-green',
        'Processing': 's-blue',
        'Taken Down': 's-red n-right',
        'Error': 's-red n-right'
    }

    const handleCheck = (index) => {
        let newPlatforms = [...platforms]
        if (newPlatforms[index].checked === true) {
            newPlatforms[index].checked = false
            setPlatforms(newPlatforms)
        } else {
            newPlatforms[index].checked = true
            setPlatforms(newPlatforms)
        }
    }

    const checkAll = () => {
        setCheckedAll(!checkedAll)
        let newPlatforms = [...platforms]
        for (let i = 0; i < newPlatforms.length; i++) {
            const element = newPlatforms[i];
            element.checked = !checkedAll
        }
        setPlatforms(newPlatforms)
    }

    const releaseStatus = (newPlatforms) => {
        const errorExist = newPlatforms.find(item => item.status === 'Error')
        const processingExist = newPlatforms.find(item => item.status === 'Processing')
        const liveExist = newPlatforms.find(item => item.status === 'Distributed')
        const takeDownExist = newPlatforms.every(item => item.status === 'Taken Down')
        if (errorExist) {
            return 'Error'
        } else if (processingExist) {
            return 'Processing'
        } else if (liveExist) {
            return 'Distributed'
        } else if (takeDownExist) {
            return 'Takedown'
        }
    }

    const handleSave = () => {
        let newReleases = [...releases]
        let newPlatforms = []
        let count = 0
        platforms.forEach(platform => {
            if (platform.checked === true) {
                if (platform.status === 'Never Distributed') {
                    newPlatforms.push({
                        id: platform.id,
                        status: 'Processing',
                        checked: platform.checked,
                        link: platform.link
                    })
                } else if (platform.status === 'Error') {
                    newPlatforms.push({
                        id: platform.id,
                        status: 'Processing',
                        checked: platform.checked,
                        link: platform.link
                    })
                } else {
                    newPlatforms.push({
                        id: platform.id,
                        status: platform.status,
                        checked: platform.checked,
                        link: platform.link
                    })
                }
            } else if (platform.status === 'Distributed') {
                newPlatforms.push({
                    id: platform.id,
                    status: 'Taken Down',
                    checked: platform.checked,
                    link: platform.link
                })
            }
            count++
        })
        if (count === platforms.length) {
            newReleases[id].status = releaseStatus(newPlatforms)
            newReleases[id].platforms = newPlatforms
            update(child(ref(db, 'users'), uid), {
                releases: newReleases
            })
            successBtn.current.click()
        }
    }
    
  return (
    <div className='dash-section'>
        <h1 className='dash-title'>Releases</h1>
        <div className="row">
            <div className="col-12 mt-4">
                <div className="dash-card">
                    <div className="dash-card-heading">
                        <h2 className="dash-card-title">
                            <Link to={'/dashboard/releases'} className='title-back-link'>
                                <i className="fi fi-rr-arrow-small-left"></i>
                            </Link>
                            {release.title}
                        </h2>
                    </div>
                    <div className="release-details">
                        <img className='release-details-img' src={release.artwork} alt="" />
                        <div className="release-details-items">
                            <div className="release-details-item">
                                <h6>Label</h6><span>{release.label}</span>
                            </div>
                            <div className="release-details-item">
                                <h6>UPC</h6><span>{release.upc}</span>
                            </div>
                            <div className="release-details-item">
                                <h6>Copyright Line</h6><span>{release.copyrightYear} {release.copyrightLine}</span>
                            </div>
                            <div className="release-details-item">
                                <h6>Publishing Line</h6><span>{release.publishingYear} {release.publishingLine}</span>
                            </div>
                            <div className="release-details-item">
                                <h6>Catalogue Number</h6><span>{release.customID || ''}</span>
                            </div>
                            <div className="release-details-item">
                                <h6>Genre #1</h6><span>{release.primaryGenre}</span>
                            </div>
                            <div className="release-details-item">
                                <h6>Artists</h6>
                                <span>
                                    {
                                        release.artists && release.artists.map(artist => (
                                            <>
                                                {artist.name} <br />
                                            </>
                                        ))
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="release-details-items">
                            <div className="release-details-item">
                                <h6>Explicit Lyrics</h6><span>No</span>
                            </div>
                            <div className="release-details-item">
                                <h6>Duration</h6><span>01:00:02</span>
                            </div>
                            <div className="release-details-item">
                                <h6>Creation Date</h6><span>{release.originalDate}</span>
                            </div>
                            <div className="release-details-item">
                                <h6>Release Date</h6><span>{release.digitalDate}</span>
                            </div>
                            <div className="release-details-item">
                                <h6>Status</h6><span>{release.status}</span>
                            </div>
                        </div>
                    </div>
                    <div className="release-stores">
                        {
                            validPlatforms.map((item, i) => (
                                <a href={item.link} target='blank' key={i}>
                                    <img src={item.logo} alt="" />
                                </a>
                            ))
                        }
                    </div>
                    <h2 className="dash-card-title mt-4">Tracks</h2>
                    <div className="table-responsive">
                        <table className="table dash-table">
                            <thead>
                                <tr>
                                    <td>Title</td>
                                    <td>Artist</td>
                                    <td>Authors</td>
                                    <td>Composers</td>
                                    <td>Duration</td>
                                    <td>Genre #1</td>
                                    <td>ISRC</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    release.tracks && release.tracks.map((track, i) => (
                                        <tr key={i}>
                                            <td>
                                                <div className="table-item">
                                                    {track.title}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-item">
                                                    {
                                                        track.artists && track.artists.map((artist, i) => (
                                                            <div key={i}>
                                                                {artist.role !== 'Lyricist' && artist.role !== 'Composer' && artist.role !== 'Composer & Lyricist' ? artist.name : ''}
                                                                {/* {i < track.artists.length - 1 && ", "} */}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-item">
                                                    {
                                                        track.artists && track.artists.map((artist, i) => (
                                                            <div key={i}>
                                                                {artist.role === 'Lyricist' || artist.role === 'Composer & Lyricist' ? artist.name : ''}
                                                                {/* {i < track.artists.length - 1 && ", "} */}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-item">
                                                    {
                                                        track.artists && track.artists.map((artist, i) => (
                                                            <div key={i}>
                                                                {artist.role === 'Composer' || artist.role === 'Composer & Lyricist' ? artist.name : ''}
                                                                {/* {i < track.artists.length - 1 && ", "} */}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-item">
                                                    {track.duration}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-item">
                                                    {track.genre}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-item">
                                                    {track.isrc}
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    {/* <h2 className="dash-card-title mt-4">Platforms</h2>
                    <div className="table-responsive">
                        <table className="table dash-table">
                            <thead>
                                <tr>
                                    <td>
                                        <div className='dash-form-check'>
                                            <input 
                                                type="checkbox" 
                                                className='form-check-input' 
                                                onChange={checkAll}
                                            />
                                        </div>
                                    </td>
                                    <td>Platform</td>
                                    <td>Status</td>
                                    <td>Link</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    platforms.map((platform, i) => (
                                        <tr key={i}>
                                            <td>
                                                <div className='dash-form-check'>
                                                    <input 
                                                        type="checkbox" 
                                                        className='form-check-input' 
                                                        checked={platform.checked}
                                                        onChange={() => handleCheck(i)}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-item d-flex align-items-center">
                                                    <img className='platform-img' src={platform.logo} alt="" />
                                                    <h6 className='ms-2'>{platform.title}</h6>
                                                </div>
                                            </td>
                                            <td>
                                                <div className={`release-status ${statusClasses[platform.status]}`}>{platform.status}</div>
                                            </td>
                                            <td>
                                                <div className='table-item'>
                                                    { platform.link === '' && '-' }
                                                    {
                                                        platform.link !== '' && (
                                                            <a href={platform.link} target='blank'><i className="fi fi-rr-arrow-up-right-from-square"></i></a>
                                                        )
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="dash-form-btns mt-3">
                        <button className='dash-form-btn' onClick={handleSave}>Save Platforms</button>
                    </div> */}
                </div>
            </div>
        </div>


        <button data-bs-toggle="modal" data-bs-target="#successModal" ref={successBtn} hidden></button>
        <div className="modal fade" id="successModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="successModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <Link to={'#'} className='success-close-btn' onClick={() => window.location.reload()}>
                            <i className="fi fi-rr-cross"></i>
                        </Link>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Platforms Updated Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ReleaseDetails