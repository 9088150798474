import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Line } from 'react-chartjs-2'
import { Chart as ChartJS } from 'chart.js/auto'
import { child, get, ref, set, update } from 'firebase/database'
import { db } from '../../firebase'
import { generate } from 'random-words'
import axios from 'axios'

const Members = () => {

    const uid = localStorage.getItem('uid')
    const mid = localStorage.getItem('mid')

    const [users, setUsers] = useState([])
    const [members, setMembers] = useState([])

    useEffect(() => {
        get(ref(db, 'users'))
        .then(snapshot => {
            if (snapshot.exists()) {
                const usersArray = Object.entries(snapshot.val()).map(([key, value]) => {
                    return {
                        id: key,
                        ...value
                    }
                })
                setUsers(usersArray)

                const currentUser = usersArray.find(user => user.id === uid)
                const userMembers = currentUser.members || []
                if (userMembers.length > 0) {
                    userMembers.forEach(member => {
                        const currentMember = usersArray.find(user => user.id === member)
                        setMembers(prevMembers => [...prevMembers, currentMember])
                    })
                }
            }
        })
    }, [])


    const successBtn = useRef()
    const removeBtn = useRef()
    const [email, setEmail] = useState('')
    const [addBtnText, setAddBtnText] = useState('Submit')
    const [removeBtnText, setRemoveBtnText] = useState('Yes')
    const [removeMemberId, setRemoveMemberId] = useState('')

    const [error, setError] = useState('')

    const handleError = (errorMsg) => {
        setError(errorMsg)
        setTimeout(() => {
            setError('')
        }, 3000);
    }

    const passwordGenerator = () => {
        const randomNumber = Math.floor(Math.random() * 9000) + 1000;
        const word = `${generate()}-${generate()}-${randomNumber}`
        const randomPassword = word.slice(0, 1).toUpperCase().concat(word.slice(1))
        return randomPassword
    }

    const addMember = (e) => {
        e.preventDefault()
        setAddBtnText('Please Wait...')
        const addressExistInMembers = members.find(member => member.email === email)
        if (addressExistInMembers) {
            handleError('Member Already Exists')
            setAddBtnText('Submit')
        } else {
            const addressExist = users.find(user => user.email === email)
            const newMembers = members.map(member => member.id)
            if (addressExist) {
                const memberTeams = addressExist.teams || []
                memberTeams.push(uid)
                update(child(ref(db, 'users'), addressExist.id), {
                    teams: memberTeams
                })
                newMembers.push(addressExist.id)
                update(child(ref(db, 'users'), uid), {
                    members: newMembers
                })
                setAddBtnText('Submit')
                successBtn.current.click()
            } else {
                const generatedPassword = passwordGenerator()
                axios.post('https://bne-backend.vercel.app/createAdmin', {
                    email: email,
                    password: generatedPassword
                })
                .then(res => {
                    set(child(ref(db, 'users'), res.data.uid), {
                        firstName: 'Invited',
                        lastName: 'User',
                        email,
                        teams: [uid],
                        role: 'member',
                    })
                    newMembers.push(res.data.uid)
                    update(child(ref(db, 'users'), uid), {
                        members: newMembers
                    })
                    setAddBtnText('Submit')
                    successBtn.current.click()
                    console.log(generatedPassword)
                })
                .catch(err => {
                    setAddBtnText('Submit')
                    handleError(err.response.data.error.message)
                })
            }
        }
    }


    const removeMember = () => {
        const member = members.find(item => item.id === removeMemberId)
        const user = users.find(item => item.id === mid)
        const memberTeams = member.teams.filter(team => team !== mid)
        const userMembers = user.members.filter(item => item !== removeMemberId)
        update(child(ref(db, 'users'), removeMemberId), {
            teams: memberTeams
        })
        update(child(ref(db, 'users'), mid), {
            members: userMembers
        })
        removeBtn.current.click()
    }

  return (
    <div className='dash-section'>
        <h1 className='dash-title'>Members</h1>
        <div className="dash-card mt-5">
            <div className="dash-card-heading">
                <h2 className="dash-card-title">Members</h2>
                <button 
                    data-bs-toggle="modal"
                    data-bs-target="#addMemberModal"
                    className="dash-card-heading-btn"
                >
                    <i className="fi fi-sr-plus"></i>
                    Add Member
                </button>
            </div>
            <div className="table-responsive mt-3">
                <table className="table dash-table wide-table">
                    <thead>
                        <tr>
                            <td>Name</td>
                            <td>Email</td>
                            <td>Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            members.map((user, i) => (
                                <tr key={i}>
                                    <td>
                                        <div className="table-item">
                                            {user.firstName} {user.lastName}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="table-item">
                                            {user.email}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="table-item d-flex">
                                            <button 
                                                className="table-action-btn a-red me-1"
                                                data-bs-target="#confirmModal"
                                                data-bs-toggle="modal"
                                                onClick={() => setRemoveMemberId(user.id)}
                                            >
                                                <i className="fi fi-rr-trash"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>

        <div className="modal fade" id="addMemberModal" tabindex="-1" aria-labelledby="addMemberModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="artist2ModalLabel">Add Member</h1>
                        <button type="button" className="btn-close" data-bs-toggle="modal" data-bs-target="#editTrackModal"></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={addMember} className="row">
                            <div className="col-12 mt-3">
                                <div className="dash-form-group">
                                    <label>Email</label>
                                    <input 
                                        type="email" 
                                        placeholder='Email' 
                                        className='dash-form-input' 
                                        onChange={e => setEmail(e.target.value)}
                                        value={email}
                                        required
                                    />
                                </div>
                            </div>
                            
                            <div className="col-12 mt-3">
                                {
                                    error !== '' && <h5 className='text-danger text-center mb-3'>{error}</h5>
                                }
                                <div className="dash-form-btns">
                                    <button 
                                        type="submit"
                                        className={`dash-form-btn ${addBtnText === 'Please Wait...' && 'disabled'}`}
                                        disabled={addBtnText === 'Please Wait...' ? true : false}
                                    >{addBtnText}</button>
                                </div>
                            </div>
                        </form>
                        <button ref={successBtn} data-bs-target="#memberSuccessModal" data-bs-toggle="modal" hidden></button>
                    </div>
                </div>
            </div>
        </div>


        <div className="modal fade" id="confirmModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="confirmModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <Link className='success-close-btn' to="#" data-bs-dismiss="modal">
                            <i className="fi fi-rr-cross"></i>
                        </Link>
                        <h5>Are you sure you want to remove this member?</h5>
                        <div className="d-flex justify-content-center mt-4">
                            <button className="dash-card-heading-btn cancel-btn share-btn me-2" data-bs-toggle="modal" data-bs-target="#addShareModal">Cancel</button>
                            <button 
                                className={`dash-card-heading-btn share-btn ${removeBtnText === 'Please Wait...' && 'disabled'}`} 
                                onClick={removeMember}
                                disabled={removeBtnText === 'Please Wait...' ? true : false}
                            >{removeBtnText}</button>
                            <button ref={removeBtn} data-bs-toggle="modal" data-bs-target="#memberSuccessModal" hidden></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className="modal fade" id="memberSuccessModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="memberSuccessModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/dashboard/members">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Members Saved Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Members