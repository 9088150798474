import { child, get, ref } from 'firebase/database'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { db } from '../../firebase'
import Pagination from '../Paginations'

const Labels = () => {

    const uid = localStorage.getItem('uid')

    const [elligible, setElligible] = useState(false)
    const [labels, setLabels] = useState([])

    useEffect(() => {
        get(child(ref(db, 'users'), uid))
        .then(snapshot => {
          snapshot.val().labelsElligible && setElligible(snapshot.val().labelsElligible)
          snapshot.val().labels && setLabels(snapshot.val().labels)
        })
    }, [])

    /////////// PAGINATION ///////////
    const itemsPerPage = 25;
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = labels.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(labels.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

  return (
    <div className='dash-section'>
        <h1 className='dash-title'>Labels</h1>
        <div className="row">
            <div className="col-12 mt-4">
                <div className="dash-card">
                    <div className="dash-card-heading">
                        <h2 className="dash-card-title">
                            Labels
                        </h2>
                        {
                            elligible || labels.length === 0 ? (
                                <Link 
                                    to={'create'} 
                                    className="dash-card-heading-btn"
                                >
                                    <i className="fi fi-sr-plus"></i>
                                    Add Label
                                </Link>
                            ) : (
                                <Link 
                                    to={'#'} 
                                    className="dash-card-heading-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#restrictModal"
                                >
                                    <i className="fi fi-sr-plus"></i>
                                    Add Label
                                </Link>
                            )
                        }

                    </div>
                    <div className="table-responsive mt-3">
                        <table className="table dash-table">
                            <thead>
                                <tr>
                                    <td>Name</td>
                                    <td>Logo</td>
                                    <td>Country</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    currentItems.map((label, i) => (
                                        <tr key={i}>
                                            <td>
                                                <div className="table-item">
                                                    {label.name}
                                                </div>
                                            </td>
                                            <td>
                                                <img className='label-logo' src={label.logo} alt="" />
                                            </td>
                                            <td>
                                                <div className="table-item">
                                                    {label.country}
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    {/* <div className="table-pagination">
                        {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                        <button key={pageNumber} className={currentPage === pageNumber && 'active'} onClick={() => handlePageChange(pageNumber)}>
                            {pageNumber}
                        </button>
                        ))}
                    </div> */}
                    <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
                </div>
            </div>
        </div>

        <div className="modal fade" id="restrictModal" tabindex="-1" aria-labelledby="restrictModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <Link to={'#'} className='success-close-btn' data-bs-dismiss="modal">
                            <i className="fi fi-rr-cross"></i>
                        </Link>
                        <i className="fi fi-rr-lock text-danger success-icon"></i>
                        <h5>You're not Allowed to Add Labels</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Labels