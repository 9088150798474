import { child, equalTo, get, onChildAdded, orderByChild, push, query, ref, remove, set, update } from 'firebase/database'
import React, { useEffect, useRef, useState } from 'react'
import { db, storage } from '../../firebase'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import countries from '../../arrays/countries.json'
import languages from '../../arrays/languages.json'
import impGenres from '../../arrays/genres.json'
import { Link } from 'react-router-dom';
import { getDownloadURL, uploadBytes, ref as sRef } from 'firebase/storage'
import JSZip from 'jszip';
import axios from 'axios';
import FileSaver from 'file-saver';
import Pagination from '../Paginations';

const AdminRolesGenres = () => {

    const [roles, setRoles] = useState([])
    const [filteredRoles, setFilteredRoles] = useState([])
    const [genres, setGenres] = useState([])
    const [filteredGenres, setFilteredGenres] = useState([])

    const [tab, setTab] = useState(1)

    let existingRoles = {}
    let existingGenres = {}


    useEffect(() => {
        get(ref(db, 'users'))
        .then(snapshot => {
            const usersData = snapshot.val() || {}; // Get the data from the snapshot
            const usersArray = Object.entries(usersData).map(([key, value]) => ({
                id: key, // Include the key as "id" property
                ...value // Spread the rest of the properties
            }));
            const releases = []
            usersArray.forEach(user => {
                    if (user.releases) {
                        user.releases.forEach(release => {
                            if (release.status === 'Distributed') releases.push(release)
                        })
                    }
                }
            )
            releases.forEach(release => {
                if (release.artists) {
                    release.artists.forEach(artist => {
                        existingRoles[artist.role.toLowerCase()] = true
                    })
                }
                existingGenres[release.primaryGenre.toLowerCase()] = true
                existingGenres[release.secondaryGenre.toLowerCase()] = true
                if (release.tracks) {
                    release.tracks.forEach(track => {
                        if (track.artists) {
                            track.artists.forEach(artist => {
                                existingRoles[artist.role.toLowerCase()] = true
                            })
                        }
                        existingGenres[track.genre.toLowerCase()] = true
                    })
                }
            })
        })
        get(ref(db, 'artistsRoles'))
        .then(snapshot => {
            const rolesData = snapshot.val() || {}; // Get the data from the snapshot
            const rolesArray = Object.entries(rolesData).map(([key, value]) => ({
                id: key, // Include the key as "id" property
                ...value, // Spread the rest of the properties
                disabled: existingRoles[value.name.toLowerCase()] !== undefined ? true : false
            }))
            const sortedRoles = rolesArray.sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
            });
            setRoles(sortedRoles)
            setFilteredRoles(sortedRoles)
        })
        get(ref(db, 'genres'))
        .then(snapshot => {
            const genresData = snapshot.val() || {}; // Get the data from the snapshot
            const genresArray = Object.entries(genresData).map(([key, value]) => ({
                id: key, // Include the key as "id" property
                ...value, // Spread the rest of the properties
                disabled: existingGenres[value.name.toLowerCase()] !== undefined ? true : false
            }))
            const sortedGenres = genresArray.sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
            });
            setGenres(sortedGenres)
            setFilteredGenres(sortedGenres)
        })
    }, [])


    /////////// PAGINATION ///////////
    const itemsPerPage = 25;
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentItems = filteredRoles.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredRoles.length / itemsPerPage);
    
    const currentItems1 = filteredGenres.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages1 = Math.ceil(filteredGenres.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    const handleSearch = (event) => {
        const term = event.target.value;

        setCurrentPage(1)

        if (tab === 1) {
            const filteredResults = roles.filter((role) => {
              const name = role.name;
              const lowerCaseTerm = term.toLowerCase();
        
              // Check if the item's properties contain the search term
              return (
                name && name.toLowerCase().includes(lowerCaseTerm)
              );
            });
        
            setFilteredRoles(filteredResults);
            } else {
            const filteredResults = genres.filter((role) => {
              const name = role.name;
              const lowerCaseTerm = term.toLowerCase();
        
              // Check if the item's properties contain the search term
              return (
                name && name.toLowerCase().includes(lowerCaseTerm)
              );
            });
        
            setFilteredGenres(filteredResults);
        }
    
    };

    
    const successBtn = useRef()
    const [name, setName] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const addRole = () => {
        const roleExist = filteredRoles.find(item => item.name === name)
        if (roleExist) {
            setErrorMessage('This Role Already Exists')
            setTimeout(() => {
                setErrorMessage('')
            }, 2000)
        } else {
            set(push(ref(db, 'artistsRoles')), {
                name: name
            })
            successBtn.current.click()
        }
    }

    const addGenre = () => {
        const genreExist = filteredGenres.find(item => item.name === name)
        if (genreExist) {
            setErrorMessage('This Genre Already Exists')
            setTimeout(() => {
                setErrorMessage('')
            }, 2000)
        } else {
            set(push(ref(db, 'genres')), {
                name: name
            })
            successBtn.current.click()
        }
    }

    const deleteRole = (roleKey) => {
        remove(child(ref(db, 'artistsRoles'), roleKey))
        const newFilteredRoles = [...filteredRoles]
        const deleteFilteredRoles = newFilteredRoles.filter(role => role.id !== roleKey)
        setFilteredRoles(deleteFilteredRoles)
    }

    const deleteGenre = (genreKey) => {
        remove(child(ref(db, 'genres'), genreKey))
        const newFilteredGenres = [...filteredGenres]
        const deleteFilteredGenres = newFilteredGenres.filter(genre => genre.id !== genreKey)
        setFilteredGenres(deleteFilteredGenres)
    }
   

  return (
    <div className="admin-page py-5">
        <div className='container'>
            <h1 className='dash-title'>Roles / Genres</h1>
            <div className="row">
                <div className="col-12 mt-4">
                    <div className="dash-card">
                        <div className="dash-card-heading mb-2">
                            <h2 className="dash-card-title">Roles / Genres</h2>
                            <button className='dash-card-heading-btn' data-bs-toggle="modal" data-bs-target="#addModal">
                                <i className="fi fi-sr-plus"></i>
                                Add {tab === 1 ? 'Role' : 'Genre'}
                            </button>
                        </div>
                        <input type="text" className='dash-input' onChange={handleSearch} placeholder={`Search ${tab === 1 ? 'Roles' : 'Genres'}...`} />
                        <div className="dash-tabs">
                            <Link 
                                to={'#'} 
                                className={`dash-tab ${tab === 1 && 'active'}`}
                                onClick={() => {
                                    setTab(1); 
                                    setCurrentPage(1)
                                }}
                                >Roles</Link>
                            <Link 
                                to={'#'} 
                                className={`dash-tab ${tab === 2 && 'active'}`}
                                onClick={() => {
                                    setTab(2); 
                                    setCurrentPage(1)
                                }}
                            >Genres</Link>
                        </div>
                        <div className="table-responsive mt-3">
                            <table className="table dash-table">
                                <thead>
                                    <tr>
                                        <td>Role</td>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tab === 1 && currentItems.map((role, i) => (
                                            <tr key={i}>
                                                <td>
                                                    <div className="table-item">
                                                        {role.name}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item d-flex">
                                                        <button 
                                                            className={`table-action-btn a-red me-1 ${role.disabled === true ? 'disabled' : ''}`}
                                                            disabled={role.disabled}
                                                            onClick={() => deleteRole(role.id)}
                                                        >
                                                            <i className="fi fi-rr-trash"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    {
                                        tab === 2 && currentItems1.map((genre, i) => (
                                            <tr key={i}>
                                                <td>
                                                    <div className="table-item">
                                                        {genre.name}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item d-flex">
                                                        <button 
                                                            className={`table-action-btn a-red me-1 ${genre.disabled === true ? 'disabled' : ''}`}
                                                            disabled={genre.disabled}
                                                            onClick={() => deleteGenre(genre.id)}
                                                        >
                                                            <i className="fi fi-rr-trash"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        
                        {
                            tab === 1 ? (
                                <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} /> 
                            ) : (
                                <Pagination currentPage={currentPage} totalPages={totalPages1} handlePageChange={handlePageChange} /> 
                            )
                        }
                    </div>
                </div>
            </div>

        </div>


        <div className="modal fade" id="addModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="addModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="modal-top mb-4">
                            <h5>Add {tab === 1 ? 'Role' : 'Genre'}</h5>
                            <i className="fi fi-rr-cross" data-bs-dismiss="modal"></i>
                        </div>
                        <div className="row">
                            <div className="col-12 mt-3">
                                <div className="dash-form-group">
                                    <label>Name</label>
                                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} className='dash-form-input' />
                                </div>
                            </div>
                        </div>
                        {errorMessage && <h6 className='text-center text-danger mt-3'>{errorMessage}</h6>}
                        <div className="d-flex justify-content-end mt-4">
                            <button 
                                className='dash-form-btn' 
                                onClick={tab === 1 ? addRole : addGenre}
                            >Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <button data-bs-toggle="modal" data-bs-target="#addSuccessModal" ref={successBtn} hidden></button>
        <div className="modal fade" id="addSuccessModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="addSuccessModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/admin/rolesandgenres">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>{tab === 1 ? 'Role' : 'Genre'} Saved!</h5>
                    </div>
                </div>
            </div>
        </div>


    </div>
  )
}

export default AdminRolesGenres