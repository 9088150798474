import { child, equalTo, get, onChildAdded, orderByChild, push, query, ref, remove, set, update } from 'firebase/database'
import React, { useEffect, useRef, useState } from 'react'
import { db, storage } from '../../firebase'
import { getDownloadURL, uploadBytes, ref as sRef } from 'firebase/storage'
import { Link, useNavigate } from 'react-router-dom'
import Papa from 'papaparse';

const AdminPlatforms = () => {

    const [platforms, setPlatforms] = useState([])

    useEffect(() => {
        get(ref(db, 'platforms'))
        .then(snapshot => {
            const platformsData = snapshot.val() || {}; // Get the data from the snapshot
            const platformsArray = Object.entries(platformsData).map(([key, value]) => ({
                id: key, // Include the key as "id" property
                ...value // Spread the rest of the properties
            }));
            setPlatforms(platformsArray);
        })
    }, [])
    
    const successBtn = useRef()

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')

    const fileInputRef = useRef(null);
    const [imgPreview, setImgPreview] = useState(false)
    const [imgSrc, setImgSrc] = useState('')
    const [uploadLabel, setUploadLabel] = useState('Choose file')
    const [previewId, setPreviewId] = useState('')

    const uploadArtwork = async (e) => {
        const file = e.target.files[0]
        const storageRef = sRef(storage, `platforms/${file.name}`);
      
        try {
          // Upload file to Firebase Storage
          await uploadBytes(storageRef, file);
      
          // Get download URL
          const downloadURL = await getDownloadURL(storageRef);
          setImgSrc(downloadURL)
          setImgPreview(true)
          setUploadLabel('Replace file')
        } catch (error) {
          console.error("Error uploading file:", error);
        }
    }      

    const removeImg = () => {
        fileInputRef.current.value = '';
        setImgSrc('')
        setImgPreview(false)
        setUploadLabel('Choose file')
    }

    

    const addPlatform = () => {
        set(push(ref(db, 'platforms')), {
            title: title,
            logo: imgSrc,
            description: description
        })
        successBtn.current.click()
    }

    const editPlatform = () => {
        update(child(ref(db, 'platforms'), previewId), {
            title: title,
            logo: imgSrc,
            description: description
        })
        successBtn.current.click()
    }

    const removePlatform = (id) => {
        remove(child(ref(db, 'platforms'), id))
        setPlatforms(platforms.filter(item => item.id !== id))
    }

  return (
    <div className="admin-page py-5">
        <div className='container'>
            <h1 className='dash-title'>Platforms</h1>
            <div className="row">
                <div className="col-12 px-1 mt-4">
                    <div className="dash-card">
                        <div className="dash-card-heading">
                            <h4>Platforms</h4>
                            <button 
                                className="dash-card-heading-btn"
                                data-bs-toggle="modal"
                                data-bs-target="#addPlatformModal"
                                onClick={() => {
                                    setTitle('')
                                    setDescription('')
                                    setImgSrc('')
                                }}
                            >
                                <i className="fi fi-sr-plus"></i>
                                Add Platform
                            </button>
                        </div>
                        <div className="table-responsive mt-4">
                            <table className="table dash-table">
                                <thead>
                                    <tr>
                                        <td>Platform</td>
                                        <td>Description</td>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        platforms.map((platform, i) => (
                                            <tr key={i}>
                                                <td>
                                                    <div className="table-item d-flex align-items-center">
                                                        <img className='platform-img' src={platform.logo} alt="" />
                                                        <h6 className='ms-2'>{platform.title}</h6>
                                                    </div>
                                                </td>
                                                <td><div className='table-item'>{platform.description}</div></td>
                                                <td>
                                                    <div className='table-item d-flex'>
                                                        <i 
                                                            className="fi fi-rr-pencil table-action-btn a-blue me-2"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#editPlatformModal"
                                                            onClick={() => {
                                                                setTitle(platform.title)
                                                                setDescription(platform.description)
                                                                setImgPreview(true)
                                                                setImgSrc(platform.logo)
                                                                setPreviewId(platform.id)
                                                            }}
                                                        ></i>
                                                        <i 
                                                            className="fi fi-rr-trash table-action-btn a-red"
                                                            onClick={() => removePlatform(platform.id)}
                                                        ></i>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


        </div>



        <div className="modal fade" id="addPlatformModal" tabindex="-1" aria-labelledby="addPlatformModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="addPlatformModalLabel">Add Platform</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12 mt-3">
                                <div className="dash-form-group">
                                    <label>Logo</label>
                                    <div className="img-file-upload">
                                        {
                                            imgPreview && (
                                                <div className='img-preview logo-preview'>
                                                    <img src={imgSrc} alt="" />
                                                    <i className="fi fi-rr-trash remove-artwork" onClick={removeImg}></i>
                                                </div>
                                            )
                                        }
                                        <div className="dash-form-file logo-uploader">
                                            <i className="fi fi-rr-cloud-upload"></i>
                                            {uploadLabel}
                                            <input type="file" ref={fileInputRef} onChange={uploadArtwork} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <div className="dash-form-group">
                                    <label>Title</label>
                                    <input type="text" value={title} onChange={e => setTitle(e.target.value)} placeholder='Title' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <div className="dash-form-group">
                                    <label>Description</label>
                                    <textarea className='dash-form-textarea' value={description} onChange={e => setDescription(e.target.value)}></textarea>
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <div className="dash-form-btns">
                                    <button 
                                        className='dash-form-btn' 
                                        onClick={addPlatform}
                                    >Submit</button>
                                </div>
                            </div>
                            <button data-bs-toggle="modal" data-bs-target="#successModal" ref={successBtn} hidden></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="editPlatformModal" tabindex="-1" aria-labelledby="editPlatformModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="editPlatformModalLabel">Edit Platform</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12 mt-3">
                                <div className="dash-form-group">
                                    <label>Logo</label>
                                    <div className="img-file-upload">
                                        {
                                            imgPreview && (
                                                <div className='img-preview logo-preview'>
                                                    <img src={imgSrc} alt="" />
                                                    <i className="fi fi-rr-trash remove-artwork" onClick={removeImg}></i>
                                                </div>
                                            )
                                        }
                                        <div className="dash-form-file logo-uploader">
                                            <i className="fi fi-rr-cloud-upload"></i>
                                            {uploadLabel}
                                            <input type="file" ref={fileInputRef} onChange={uploadArtwork} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <div className="dash-form-group">
                                    <label>Title</label>
                                    <input type="text" value={title} onChange={e => setTitle(e.target.value)} placeholder='Title' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <div className="dash-form-group">
                                    <label>Description</label>
                                    <textarea className='dash-form-textarea' value={description} onChange={e => setDescription(e.target.value)}></textarea>
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <div className="dash-form-btns">
                                    <button 
                                        className='dash-form-btn' 
                                        onClick={editPlatform}
                                    >Submit</button>
                                </div>
                            </div>
                            <button data-bs-toggle="modal" data-bs-target="#successModal" ref={successBtn} hidden></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className="modal fade" id="successModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="successModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/admin/platforms">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Platforms Updated Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>


    </div>
  )
}

export default AdminPlatforms