import React, { useEffect, useRef, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import logo from '../../assets/logo.svg'
import { signOut } from 'firebase/auth'
import { auth, db } from '../../firebase'
import { child, get, ref } from 'firebase/database'
import Teams from './Teams'

const Sidebar = () => {

    const navigate = useNavigate()
    const closeBtn = useRef()
    const aid = localStorage.getItem('aid')
    const uid = localStorage.getItem('uid')
    const mid = localStorage.getItem('mid')

    const [role, setRole] = useState('')
    const [users, setUsers] = useState([])
    const [teams, setTeams] = useState([])
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [trendsAllowed, setTrendsAllowed] = useState(false)

    useEffect(() => {
        
        get(ref(db, 'users'))
        .then(snapshot => {
            if (snapshot.exists()) {
                const usersArray = Object.entries(snapshot.val()).map(([key, value]) => {
                    return {
                        id: key,
                        ...value
                    }
                })
                setUsers(usersArray)

                const currentUser = usersArray.find(user => user.id === mid)
                setTeams(currentUser.teams || [])
                setRole(currentUser.role || '')
                setTrendsAllowed(currentUser.trendsAllowed == true ? true : false)
                if (currentUser.role === 'user') {
                    setName(currentUser.firstName + ' ' + currentUser.lastName)
                    setEmail(currentUser.email || '')
                }
                const userTeams = currentUser.teams || []
                if (userTeams.length > 0) {
                    userTeams.forEach(team => {
                        const currentTeam = usersArray.find(user => user.id === team)
                        if (currentTeam) setTeams(prevTeams => [...prevTeams, currentTeam])
                    })
                }
            }
        })
    }, [])

    const logout = () => {
        signOut(auth)
        .then(() => navigate('/'))
    }

  return (
    <div className='sidebar'>
        <Link to={'/'} className='sidebar-logo'>
            <img src={logo} alt="" />
        </Link>
        <div className="sidebar-links">
            {
                teams.length > 0 && (
                    <NavLink to={'teams'} ativeClassName="active">
                        <i className="fi fi-tr-employees"></i>
                        Teams
                    </NavLink>
                )
            }
            <NavLink to={'panel'} ativeClassName="active">
                <i className="fi fi-tr-house-blank"></i>
                Dashboard
            </NavLink>
            <NavLink to={'releases'} ativeClassName="active">
                <i className="fi fi-ts-rocket-lunch"></i>
                Releases
            </NavLink>
            <NavLink to={'artists'} ativeClassName="active">
                <i className="fi fi-tr-music-note"></i>
                Artists
            </NavLink>
            <NavLink to={'labels'} ativeClassName="active">
                <i className="fi fi-tr-ticket-alt"></i>
                Labels
            </NavLink>
            <NavLink to={'analytics'} ativeClassName="active">
                <i className="fi fi-tr-stats"></i>
                Analytics
            </NavLink>
            {
                trendsAllowed && (
                    <NavLink to={'dailyTrends'} ativeClassName="active">
                        <i className="fi fi-tr-arrow-trend-up"></i>
                        Daily Trends
                    </NavLink>
                )
            }
            <NavLink to={'reports'} ativeClassName="active">
                <i className="fi fi-tr-rectangle-vertical-history"></i>
                Reports
            </NavLink>
            <NavLink to={'payouts'} ativeClassName="active">
                <i className="fi fi-tr-usd-circle"></i>
                Payouts
            </NavLink>
            <NavLink to={'claims'} ativeClassName="active">
                <i className="fi fi-tr-memo-circle-check"></i>
                Claims
            </NavLink>
            <NavLink to={'profile'} ativeClassName="active">
                <i className="fi fi-tr-user-pen"></i>
                Profile
            </NavLink>
            <NavLink to={'splits'} ativeClassName="active">
                <i className="fi fi-tr-split"></i>
                Splits
            </NavLink>
            {/* {
                role === 'user' && uid === mid && (
                    <NavLink to={'members'} ativeClassName="active">
                        <i className="fi fi-tr-users-alt"></i>
                        Members
                    </NavLink>
                )
            } */}
            {
                aid && (
                    <Link 
                        to={'/admin/users'}
                        onClick={() => localStorage.setItem('uid', aid)}
                    >
                        <i className="fi fi-tr-low-vision"></i>
                        Stop
                    </Link>
                )
            }
            <Link to={'#'} onClick={logout}>
                <i className="fi fi-tr-leave"></i>
                Logout
            </Link>
        </div>


        {/* TEAMS */}
        <div className="modal fade" id="memberSuccessModal" tabindex="-1" aria-labelledby="memberSuccessModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                      <div className="modal-top">
                          <h5>Teams</h5>
                          <i className="fi fi-rr-cross" data-bs-dismiss="modal" ref={closeBtn}></i>
                      </div>
                  </div>
                  <div className="modal-body">
                    <div className="teams-items">
                        {
                            email !== '' && (
                                <div 
                                    className="teams-item"
                                    onClick={() => {
                                        localStorage.setItem('uid', mid)
                                        closeBtn.current.click()
                                        navigate('/dashboard/panel')
                                    }}
                                >
                                    <div className="teams-item-text">
                                        <h5>{name} <span>(Me)</span></h5>
                                        <h6>{email}</h6>
                                    </div>
                                    {mid === uid && <i className="fi fi-rr-check-circle text-success"></i>}
                                </div>
                            )
                        }
                        {
                            teams.map((team, i) => (
                                <div 
                                    className="teams-item" 
                                    key={i}
                                    onClick={() => {
                                        localStorage.setItem('uid', team.id)
                                        closeBtn.current.click()
                                        navigate('/dashboard/panel')
                                    }}
                                >
                                    <div className="teams-item-text">
                                        <h5>{team.firstName} {team.lastName}</h5>
                                        <h6>{team.email}</h6>
                                    </div>
                                    {uid === team.id && <i className="fi fi-rr-check-circle text-success"></i>}
                                </div>
                            ))
                        }
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Sidebar