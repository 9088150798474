import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { db } from '../../firebase'
import { child, get, ref, remove, set, update } from 'firebase/database'
import axios from 'axios'
import Pagination from '../Paginations'

const Payouts = () => {

    const uid = localStorage.getItem('uid')
    const [balance, setBalance] = useState(0)
    const [totalPaid, setTotalPaid] = useState(0)
    const [payouts, setPayouts] = useState([])
    const [paymentSetup, setPaymentSetup] = useState(false)
    const [setupRequest, setSetupRequest] = useState(false)

    const statusClasses = {
        Approved: 's-green',
        Pending: 's-orange',
        Declined: 's-red n-right'
    }

    useEffect(() => {
        get(child(ref(db, 'users'), uid))
        .then(snapshot => {
            // setBalance(snapshot.val().balance.toFixed(2) || 0)
            // setTotalPaid(snapshot.val().totalPaid.toFixed(2) || 0)
            snapshot.val().payouts && setPayouts(snapshot.val().payouts)
            snapshot.val().totalPaid && setTotalPaid(snapshot.val().totalPaid.toFixed(2))
            snapshot.val().balance && setBalance(snapshot.val().balance.toFixed(2))
            snapshot.val().paymentSetup !== undefined && setPaymentSetup(snapshot.val().paymentSetup)
            snapshot.val().setupRequest !== undefined && setSetupRequest(snapshot.val().setupRequest)
        })
    }, [])


    const successBtn = useRef()
    const [paymentMethod, setPaymentMethod] = useState('Bank Transfer')
    const [amount, setAmount] = useState(0)

    const currentDate = () => {
        const day = String(new Date().getDate()).padStart(2, '0'); // Get the day and pad with leading zero if needed
        const month = String(new Date().getMonth() + 1).padStart(2, '0'); // Get the month (0-indexed) and pad with leading zero
        const year = new Date().getFullYear(); // Get the full year
      
        return `${year}/${month}/${day}`; // Construct the "dd/mm/yyyy" format
    };

    const requestPayout = () => {
        function generateRandomID() {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let randomID = '';
            for (let i = 0; i < 5; i++) {
                randomID += characters.charAt(Math.floor(Math.random() * characters.length));
            }
            return randomID;
        }
        
        const payoutID = generateRandomID();

        const newPayout = {
            requestDate: currentDate(),
            method: paymentMethod,
            currency: 'USD',
            amount: amount,
            paymentDate: '-',
            status: 'Pending',
            id: payoutID
        }
        const newPayouts = [...payouts, newPayout]
        update(child(ref(db, 'users'), uid), {
            payouts: newPayouts
        })
        .then(() => successBtn.current.click())
    }

    

    const setupSuccessBtn = useRef()
    const sendSetupRequest = () => {
        update(child(ref(db, 'users'), uid), {
            setupRequest: true
        })
        .then(() => setupSuccessBtn.current.click())
    }


    /////////// PAGINATION ///////////
    const itemsPerPage = 25;
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = payouts.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(payouts.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    
    
  return (
    <div className='dash-section'>
        <h1 className='dash-title'>Payouts</h1>
        <div className="row">
            <div className="col-lg-7 mt-4">
                <div className="dash-card">
                    <div className="payouts-widget-info">
                        <h6>Total Paid:</h6>
                        <span>${totalPaid}</span>
                    </div>
                    <div className="payouts-widget-info">
                        <h6>Available Balance:</h6>
                        <span>${balance}</span>
                    </div>
                    <Link 
                        className="dash-card-heading-btn mt-4 w-100 d-flex justify-content-center mb-0"
                        data-bs-toggle="modal"
                        data-bs-target="#requestModal"
                    >
                        Request Withdraw
                    </Link>
                </div>
            </div>
            <div className="col-lg-5 mt-4">
                <div className="dash-card payout-method-card">
                    <h5>Manage Payout Methods</h5>
                    <h6>Add or update your details</h6>
                    {
                        setupRequest === false && paymentSetup === true && <a href={'https://suppliers.tipalti.com/Qinetic/account/login'} target='blank'>Update Details</a>
                    }
                    {
                        setupRequest !== true && setupRequest !== 'Sent' && paymentSetup === false && <Link to={'#'} onClick={sendSetupRequest}>Add Payment Details</Link>
                    }
                    {
                        setupRequest === true && (
                            <div className="payout-check">
                                <i className="fi fi-ts-check-circle"></i>
                                Setup request sent!
                            </div>
                        )
                    }
                    {
                        setupRequest === 'Sent' && (
                            <div className="payout-check">
                                <i className="fi fi-ts-check-circle"></i>
                                You received setup invite!
                            </div>
                        )
                    }
                    {
                        paymentSetup && setupRequest === false && (
                            <div className="payout-check">
                                <i className="fi fi-ts-check-circle"></i>
                                You have a payout account set up
                            </div>
                        )
                    }
                </div>
            </div>
            <div className="col-12 mt-4">
                <div className="dash-card">
                    <div className="dash-card-heading">
                        <h2 className="dash-card-title">
                            Withdraws
                        </h2>
                        {/* <div className="dash-card-heading-right">
                            <h6>Balance: <span>${balance}</span></h6>
                            <Link 
                                className="dash-card-heading-btn"
                                data-bs-toggle="modal"
                                data-bs-target="#requestModal"
                            >
                                Request Withdraw
                            </Link>
                        </div> */}
                    </div>
                    <div className="table-responsive mt-3">
                        <table className="table dash-table">
                            <thead>
                                <tr>
                                    <td>Requested at</td>
                                    <td>Payment Method</td>
                                    <td>Currency</td>
                                    <td>Amount</td>
                                    <td>Payment Date</td>
                                    <td>Status</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    currentItems.map((payout, i) => (
                                        <tr key={i}>
                                            <td>
                                                <div className="table-item">
                                                    {payout.requestDate}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-item">
                                                    {payout.method}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-item">
                                                    {payout.currency}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-item">
                                                    {payout.amount}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-item">
                                                    {payout.paymentDate}
                                                </div>
                                            </td>
                                            <td>
                                                <div className={`release-status ${statusClasses[payout.status]}`}>{payout.status}</div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    {/* <div className="table-pagination">
                        {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                        <button key={pageNumber} className={currentPage === pageNumber && 'active'} onClick={() => handlePageChange(pageNumber)}>
                            {pageNumber}
                        </button>
                        ))}
                    </div> */}
                    <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
                </div>
            </div>
        </div>


        <div className="modal fade" id="requestModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="requestModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="modal-top mb-4">
                            <h5>Request Withdraw</h5>
                            <i className="fi fi-rr-cross" data-bs-dismiss="modal"></i>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Payment Method</label>
                                    <select value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)} className='dash-form-select'>
                                        <option>Bank Transfer</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Amount ($)</label>
                                    <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} className='dash-form-input' />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end mt-4">
                            <button 
                                className={`dash-form-btn ${amount * 1 < 1 || amount * 1 > balance ? 'disabled' : ''}`} 
                                data-bs-dismiss="modal"
                                disabled={amount * 1 < 1 || amount * 1 > balance ? true : false}
                                onClick={requestPayout}
                            >Submit</button>
                            <button data-bs-toggle="modal" data-bs-target="#successModal" ref={successBtn} hidden></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="successModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="successModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/dashboard/payouts">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Payout Request Sent!</h5>
                    </div>
                </div>
            </div>
        </div>

        <button data-bs-toggle="modal" data-bs-target="#setupSuccessModal" ref={setupSuccessBtn} hidden></button>
        <div className="modal fade" id="setupSuccessModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="setupSuccessModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/dashboard/payouts">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Setup Request Sent!</h5>
                        <p>You'll receive an email within 24 hours</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Payouts