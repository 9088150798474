import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { db } from '../../firebase';
import { child, equalTo, get, orderByChild, push, query, ref, set, update } from 'firebase/database';
import Pagination from '../Paginations';

const Releases = () => {

    const uid = localStorage.getItem('uid')
    const [releases, setReleases] = useState([])
    const [drafts, setDrafts] = useState([])
    const [errorNotes, setErrorNotes] = useState('')
    const [editIndex, setEditIndex] = useState('')

    const closeRef = useRef()

    const statusClasses = {
        Distributed: 's-green',
        Processing: 's-blue',
        Takedown: 's-orange',
        Error: 's-red'
    }

    useEffect(() => {
        get(child(ref(db, 'users'), uid))
        .then(snapshot => {
            if (snapshot.val().releases !== undefined) {
                setReleases(snapshot.val().releases)
            } else {
                //
            }
            if (snapshot.val().drafts !== undefined) {
                setDrafts(snapshot.val().drafts)
            } else {
                //
            }
        })
    }, [])

    const deleteDraft = (draftID) => {
        const currentDrafts = [...drafts]
        currentDrafts.splice(draftID, 1)
        update(child(ref(db, 'users'), uid), {
            drafts: currentDrafts
        })
        setDrafts(currentDrafts)
    }

    /////////// PAGINATION ///////////
    const itemsPerPage = 25;
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = releases.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(releases.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


  return (
    <div className='dash-section'>
        <h1 className='dash-title'>Releases</h1>
        <div className="row">
            <div className="col-12 mt-4">
                <div className="dash-card">
                    <div className="dash-card-heading">
                        <h2 className="dash-card-title">Releases</h2>
                        <Link to={'create'} className="dash-card-heading-btn" onClick={() => localStorage.removeItem('release')}>
                            <i className="fi fi-sr-plus"></i>
                            New Release
                        </Link>
                    </div>
                    <div className="table-responsive mt-3">
                        <table className="table dash-table">
                            <thead>
                                <tr>
                                    <td>Artwork</td>
                                    <td>Status</td>
                                    <td>Name</td>
                                    <td>Primary Artists</td>
                                    <td>Release Date</td>
                                    <td>UPC</td>
                                    <td>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    currentItems.map((release, i) => (
                                        <tr key={i}>
                                            <td>
                                                <Link to={`/dashboard/releases/${i}`}>
                                                    <img className='release-img' src={release.artwork} alt="" />
                                                </Link>
                                            </td>
                                            <td>
                                                <div 
                                                className={`release-status ${statusClasses[release.status]}`}
                                                >
                                                    {release.status}
                                                    {
                                                        release.status === "Error" && (
                                                            <button onClick={() => {setErrorNotes(release.statusNotes); setEditIndex(i)}} className='release-status-icon' data-bs-toggle="modal" data-bs-target="#notesModal">
                                                                View notes
                                                            </button>
                                                        )
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-item">
                                                    <Link to={`/dashboard/releases/${i}`} className='text-dark'>{release.title}</Link> <br />
                                                    {/* <span>Release, Album, {release.label}</span> */}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-item">
                                                    {
                                                        release.artists && release.artists.map((artist, i) => (
                                                            artist.role === 'Primary Artist' && (
                                                                <div key={i}>
                                                                    {artist.name}
                                                                    {i < release.artists.filter(item => item.role === 'Primary Artist').length - 1 && ", "}
                                                                </div>
                                                            )
                                                        ))
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-item">
                                                    {release.originalDate}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-item">
                                                    {release.upc}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-item d-flex">
                                                    <Link 
                                                        className="table-action-btn a-blue me-1"
                                                        to={`edit/${i}`}
                                                    >
                                                        <i className="fi fi-rr-pencil"></i>
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    {/* <div className="table-pagination">
                        {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                        <button key={pageNumber} className={currentPage === pageNumber && 'active'} onClick={() => handlePageChange(pageNumber)}>
                            {pageNumber}
                        </button>
                        ))}
                    </div> */}
                    <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
                </div>
            </div>
            <div className="col-12 mt-4">
                <div className="dash-card">
                    <div className="dash-card-heading">
                        <h2 className="dash-card-title">Drafts</h2>
                    </div>
                    <div className="row">
                        {
                            drafts.map((draft, i) => (
                                <div className="col-md-6 mt-3" key={i}>
                                    <div className="draft-card">
                                        <h6>{draft.id}</h6>
                                        <div className="draft-card-actions">
                                            <Link to={'/dashboard/releases/create'} onClick={() => localStorage.setItem('release', JSON.stringify(draft))} >
                                                <i 
                                                    className="fi fi-rr-pencil table-action-btn a-blue"
                                                ></i>
                                            </Link>
                                            <i 
                                                className="fi fi-rr-trash table-action-btn a-red ms-2"
                                                onClick={() => deleteDraft(i)}
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>


        <div className="modal fade" id="notesModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="notesModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="modal-top mb-4">
                            <h5>Error</h5>
                            <i ref={closeRef} className="fi fi-rr-cross" data-bs-dismiss="modal"></i>
                        </div>
                        <p>{errorNotes}</p>
                        <div className="d-flex justify-content-end">
                            <Link to={`/dashboard/releases/edit/${editIndex}`} onClick={() => closeRef.current.click()} className='dash-form-btn'>Edit Release</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
  )
}

export default Releases