import { child, equalTo, get, onChildAdded, orderByChild, query, ref, update } from 'firebase/database'
import React, { useEffect, useRef, useState } from 'react'
import { db } from '../../firebase'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS for styling
import { enGB } from 'date-fns/locale';
import Papa from 'papaparse';
import { CSVLink } from 'react-csv';

const AdminPayouts = () => {

    const [payouts, setPayouts] = useState([])
    const [requests, setRequests] = useState([])
    const [users, setUsers] = useState([])
    const [filteredUsers, setFilteredUsers] = useState([])

    const successBtn = useRef()

    useEffect(() => {
        get(ref(db, 'users'))
        .then(snapshot => {
            const usersData = snapshot.val() || {}; // Get the data from the snapshot
            const usersArray = Object.entries(usersData).map(([key, value]) => ({
                id: key, // Include the key as "id" property
                ...value // Spread the rest of the properties
            }));
            setUsers(usersArray)
            const flattenedData = usersArray.flatMap(user => 
                user.payouts !== undefined && user.payouts.map(payout => ({
                    key: user.id,
                    id: payout.id,
                    email: user.email,
                    requestDate: payout.requestDate,
                    currency: payout.currency,
                    amount: payout.amount,
                    status: payout.status,
                    payeeID: user.payeeID || '-'
                }))
            );
            const pendingPayouts = flattenedData.filter(payout => payout.status === 'Pending')
            const sentRequests = usersArray.filter(user => user.setupRequest === true)
            // const removeLast = pendingPayouts.pop()
            setPayouts(pendingPayouts)
            setRequests(sentRequests)
        })
    }, [])


    function getCurrentMonthYear() {
        const date = new Date();
        const options = { year: 'numeric', month: 'long' };
        return date.toLocaleDateString('en-US', options).toUpperCase();
    }

    function getCurrentDate() {
        const date = new Date();
        const month = date.getMonth() + 1; // Months are zero-indexed
        const day = date.getDate();
        const year = date.getFullYear().toString().slice(-2); // Get the last two digits of the year
        
        return `${month}/${day}/${year}`;
    }

    const monthYear = getCurrentMonthYear()
    const scheduledDate = getCurrentDate()
    const payoutFilename = "payout-" + getCurrentDate().replaceAll('/', '-') + '.csv'

    const csvPayouts = payouts.map(payout => ({
        'Idap': payout.payeeID,
        'Amount': payout.amount,
        'SubmittedAmountCurrency': payout.currency,
        'RefCode': '',
        'EWalletMessage': '',
        'BankingMessage': `${payout.payeeID} ${monthYear}`,
        'IgnoreThresholds': 'TRUE',
        'EmailMessage': '',
        'ScheduledDate': scheduledDate,
        'VisibleWhenScheduled': 'TRUE',
        'IncomeType': 'OtherIncome',
        'MaxWithholding': 'FALSE',
        'IgnoreTaxValidation': 'FALSE',
        'AdditionalWithholding': '',
        'TreatyLimitExceeded': 'FALSE',
        'UsSource': ''
    }))

    const paymentMethods = [
        'Wire Transfer',
        'Paypal',
        'MFS',
        'ACH'
    ]

    const currentDate = () => {
        const day = String(new Date().getDate()).padStart(2, '0'); // Get the day and pad with leading zero if needed
        const month = String(new Date().getMonth() + 1).padStart(2, '0'); // Get the month (0-indexed) and pad with leading zero
        const year = new Date().getFullYear(); // Get the full year
      
        return `${year}/${month}/${day}`; // Construct the "dd/mm/yyyy" format
    };


    const handleDecline = (key, id) => {
        const payoutUser = users.find(user => user.id === key)
        const updatedPayouts = payoutUser.payouts.map(payout => {
            if (payout.id === id) {
                // Update the specific object
                return { ...payout, status: "Declined" };
            }
            return payout; // Keep other objects unchanged
        });
        update(child(ref(db, 'users'), key), {
            payouts: updatedPayouts,
        }).then(() => successBtn.current.click())
    }
    const handleApprove = (key, id, amount) => {
        const payoutUser = users.find(user => user.id === key)
        const updatedBalance = payoutUser.balance - amount
        const updatedTotalPaid = payoutUser.totalPaid + amount * 1 || amount * 1
        const updatedPayouts = payoutUser.payouts.map(payout => {
            if (payout.id === id) {
                // Update the specific object
                return { ...payout, status: "Approved", paymentDate: currentDate(), };
            }
            return payout; // Keep other objects unchanged
        });
        update(child(ref(db, 'users'), key), {
            payouts: updatedPayouts,
            balance: updatedBalance,
            totalPaid: updatedTotalPaid
        }).then(() => successBtn.current.click())
    }

    const sentSuccessBtn = useRef()
    const sentInvite = (key) => {
        update(child(ref(db, 'users'), key), {
            setupRequest: 'Sent'
        }).then(() => sentSuccessBtn.current.click())
    }



    const [errorMsg, setErrorMsg] = useState('')

    const handleError = (msg) => {
        setErrorMsg(msg)
        setTimeout(() => {
            setErrorMsg('')
        }, 3000);
    }


    const payoutSuccess = useRef()
    const [payoutUser, setPayoutUser] = useState('')
    const [paymentMethod, setPaymentMethod] = useState(paymentMethods[0])
    const [amount, setAmount] = useState(0)
    const [reqDate, setReqDate] = useState(new Date())
    const [payDate, setPayDate] = useState(new Date())
    const [uploadMessage, setUploadMessage] = useState('Upload From CSV')
    const [notFoundUsers, setNotFoundUsers] = useState([])

    const filterUsers = (e) => {
        setPayoutUser(e.target.value)
        if (e.target.value.length > 1) {
            setFilteredUsers(users.filter(item => item.email.includes(e.target.value.toLowerCase())))
        } else setFilteredUsers([])
    }

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setUploadMessage('Please Wait...')

        if (file) {
            Papa.parse(file, {
                complete: (result) => {
                    handlePayoutsFile(result.data)
                },
                header: true, 
            });
        }
    }

    const handlePayoutsFile = (payoutsFile) => {
        const usersToUpdate = []
        const usersNotFound = []
        payoutsFile.forEach(user => {
            const dbUser = users.find(item => item.email === user.user)
            if (dbUser) {
                usersToUpdate.push({...user, id: dbUser.id, payouts: dbUser.payouts})
            } else usersNotFound.push(user.user)
        })
        if (usersNotFound.length > 0) {
            setNotFoundUsers(usersNotFound)
            setUploadMessage('Upload From CSV')
        } else {
            usersToUpdate.forEach(user => {

                const userPayouts = user.payouts || []

                function generateRandomID() {
                    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                    let randomID = '';
                    for (let i = 0; i < 5; i++) {
                        randomID += characters.charAt(Math.floor(Math.random() * characters.length));
                    }
                    return randomID;
                }
                
                const payoutID = generateRandomID();
        
                const newPayout = {
                    requestDate: user.request_date,
                    method: user.payment_method,
                    currency: 'USD',
                    amount: user.amount,
                    paymentDate: user.payment_date,
                    status: 'Approved',
                    id: payoutID
                }

                userPayouts.push(newPayout)

                update(child(ref(db, 'users'), user.id), {
                    payouts: userPayouts
                })
            })
            setUploadMessage('Upload From CSV')
            payoutSuccess.current.click()
        }
    }

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with leading zero
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-indexed, hence +1)
        const year = date.getFullYear(); // Get year
      
        return `${year}/${month}/${day}`; // Construct "dd/mm/yyyy" format
    };

    const addPayout = () => {

        const userPayout = users.find(user => user.email === payoutUser) 
        if (userPayout) {

            const userPayouts = userPayout.payouts || []

            function generateRandomID() {
                const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let randomID = '';
                for (let i = 0; i < 5; i++) {
                    randomID += characters.charAt(Math.floor(Math.random() * characters.length));
                }
                return randomID;
            }
            
            const payoutID = generateRandomID();
    
            const newPayout = {
                requestDate: formatDate(reqDate),
                method: paymentMethod,
                currency: 'USD',
                amount: amount,
                paymentDate: formatDate(payDate),
                status: 'Approved',
                id: payoutID
            }

            userPayouts.push(newPayout)

            update(child(ref(db, 'users'), userPayout.id), {
                payouts: userPayouts
            })

            payoutSuccess.current.click()

        } else handleError('User not found')
    }

  return (
    <div className="admin-page py-5">
        <div className='container'>
            <h1 className='dash-title'>Payouts</h1>
            <div className="row">
                <div className="col-12 mt-4">
                    <div className="dash-card">
                        <div className="dash-card-heading">
                            <h2 className="dash-card-title">Payouts Requests</h2>
                            <div className="d-flex">
                                <button className="dash-card-heading-btn" data-bs-toggle="modal" data-bs-target="#addPayoutModal">
                                    <i className="fi fi-sr-plus"></i>
                                    Add Payout
                                </button>
                                <CSVLink className="dash-card-heading-btn ms-2" data={csvPayouts} filename={payoutFilename}>
                                    <i className="fi fi-rr-file-export me-2"></i>
                                    Export CSV
                                </CSVLink>
                            </div>
                        </div>
                        <div className="table-responsive mt-3">
                            <table className="table dash-table">
                                <thead>
                                    <tr>
                                        <td>User</td>
                                        <td>Request Date</td>
                                        <td>Currency</td>
                                        <td>Amount</td>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        payouts.map((payout, i) => (
                                            <tr key={i}>
                                                <td>
                                                    <div className="table-item">
                                                        {payout.email}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        {payout.requestDate}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        {payout.currency}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        {payout.amount}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item d-flex">
                                                        <button 
                                                            className="table-action-btn a-red me-1"
                                                            onClick={() => handleDecline(payout.key, payout.id)}
                                                        >
                                                            <i className="fi fi-br-cross"></i>
                                                        </button>
                                                        <button 
                                                            className="table-action-btn a-green"
                                                            onClick={() => handleApprove(payout.key, payout.id, payout.amount)}
                                                        >
                                                            <i className="fi fi-br-check"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-4">
                    <div className="dash-card">
                        <div className="dash-card-heading">
                            <h2 className="dash-card-title">Payout Setup Invite Requests</h2>
                        </div>
                        <div className="table-responsive mt-3">
                            <table className="table dash-table">
                                <thead>
                                    <tr>
                                        <td>Name</td>
                                        <td>Email</td>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        requests.map((request, i) => (
                                            <tr key={i}>
                                                <td>
                                                    <div className="table-item">
                                                        {request.firstName} {request.lastName}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        {request.email}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item d-flex">
                                                        <button 
                                                            className="table-action-btn a-green"
                                                            onClick={() => sentInvite(request.id)}
                                                        >
                                                            <i className="fi fi-br-check"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


        </div>


        <button ref={successBtn} data-bs-target="#successModal" data-bs-toggle="modal" hidden></button>
        <div className="modal fade" id="successModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="successModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/admin/payouts">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Payout Updated Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>

        <button ref={sentSuccessBtn} data-bs-target="#sentSuccessModal" data-bs-toggle="modal" hidden></button>
        <div className="modal fade" id="sentSuccessModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="sentSuccessModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/admin/payouts">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Request Updated Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>


        <div className="modal fade" id="addPayoutModal" tabindex="-1" aria-labelledby="addPayoutModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="addPayoutModalLabel">Add Payout</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12 mt-3">
                                <div className="dash-form-file py-4">
                                    <i className="fi fi-rr-cloud-upload"></i>
                                    {uploadMessage}
                                    <input type="file" onChange={handleFileUpload} />
                                </div>
                            </div>
                            {
                                notFoundUsers.length > 0 && (
                                    <div className="col-12 mt-3">
                                        {
                                            notFoundUsers.map((user, i) => (
                                                <h6 className='text-danger' key={i}>User "{user}" not found</h6>
                                            ))
                                        }
                                    </div>
                                )
                            }
                            <div className="col-12 mt-3">
                                <div className="dash-form-group">
                                    <label>User</label>
                                    <div className="dropdown dash-form-dropdown">
                                        <input 
                                            type="text" 
                                            className='dash-form-input' 
                                            placeholder='Select user' 
                                            data-bs-toggle="dropdown" 
                                            aria-expanded="false"
                                            value={payoutUser}
                                            onChange={filterUsers}
                                        />
                                        <ul className="dropdown-menu">
                                            {
                                                filteredUsers.map((item, i) => (
                                                    <li key={i} onClick={() => setPayoutUser(item.email)}>
                                                        {item.email}
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Amount ($)</label>
                                    <input type="text" value={amount} onChange={e => setAmount(e.target.value)} placeholder='Amount' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>User</label>
                                    <div className="dropdown dash-form-dropdown">
                                        <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {paymentMethod}
                                        </button>
                                        <ul className="dropdown-menu">
                                            {
                                                paymentMethods.map((item, i) => (
                                                    <li key={i} onClick={() => setPaymentMethod(item)}>
                                                        {item}
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Request Date</label>
                                    <DatePicker
                                        id="date-picker"
                                        selected={reqDate}
                                        onChange={e => setReqDate(e)}
                                        dateFormat="yyyy/MM/dd"
                                        locale={enGB} 
                                        className='dash-form-input'
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Payment Date</label>
                                    <DatePicker
                                        id="date-picker"
                                        selected={payDate}
                                        onChange={e => setPayDate(e)}
                                        dateFormat="yyyy/MM/dd"
                                        locale={enGB} 
                                        className='dash-form-input'
                                    />
                                </div>
                            </div>
                            {
                                errorMsg !== '' && (
                                    <div className="col-12 mt-3">
                                        <h6 className='text-danger text-center'>{errorMsg}</h6>
                                    </div>
                                )
                            }
                            <div className="col-12 mt-3">
                                <div className="dash-form-btns">
                                    <button 
                                        className='dash-form-btn' 
                                        onClick={addPayout}
                                    >Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <button ref={payoutSuccess} data-bs-target="#payoutSuccessModal" data-bs-toggle="modal" hidden></button>
        <div className="modal fade" id="payoutSuccessModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="payoutSuccessModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/admin/payouts">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Payouts Added Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>

    </div>
  )
}

export default AdminPayouts