import { child, equalTo, get, onChildAdded, orderByChild, query, ref, update } from 'firebase/database'
import React, { useEffect, useRef, useState } from 'react'
import { db, storage } from '../../firebase'
import { getDownloadURL, uploadBytes, ref as sRef } from 'firebase/storage'
import { Link, useNavigate } from 'react-router-dom'
import Papa from 'papaparse';
import { CSVLink } from 'react-csv'
import axios from 'axios'

const AdminReportsHistory = () => {

    const navigate = useNavigate()

    const [reports, setReports] = useState([])
    const [users, setUsers] = useState([])

    useEffect(() => {
        get(ref(db, 'reports'))
        .then(snapshot => {
            const reportsData = snapshot.val() || {};
            const reportsArray = Object.entries(reportsData).map(([key, item]) => ({id: key, ...item}))
            setReports(reportsArray.reverse())
        })
        get(ref(db, 'users'))
        .then(snapshot => {
            const usersData = snapshot.val() || {}; // Get the data from the snapshot
            const usersArray = Object.entries(usersData).map(([key, value]) => ({
                id: key, // Include the key as "id" property
                ...value // Spread the rest of the properties
            }));
            setUsers(usersArray)
        })
    }, [])


    function parseCsv(csvText) {
        const rows = csvText.trim().split('\n');
        return rows.map(row => row.split(','));
    }

    const updateOverview = (overviewObject) => {
        for (let i = 0; i < overviewObject.length; i++) {
            const overviewRow = overviewObject[i]
            const matchingUser = users.find(user => user.email === overviewRow.email)
            if (matchingUser) {
                if (matchingUser.payeeID !== undefined) {
                    overviewObject[i].payeeID = matchingUser.payeeID
                } else overviewObject[i].payeeID = ''
            }
        }
        localStorage.setItem('reportOverview', JSON.stringify(overviewObject))
        navigate('details')
    }

    const getFileData = (url, fileID) => {
        axios.get(url)
        .then(res => {
            Papa.parse(res.data, {
                header: true, 
                complete: (result) => {
                    const payeeReports = result.data
                    for (let i = 0; i < payeeReports.length; i++) {
                        const reportUPC = payeeReports[i]['UPC'].replace('\'', '') * 1;
                        let reportPayee = ''
                        users.forEach(user => {
                            let hasUPC = false
                            if (user.releases) {
                                user.releases.forEach(release => {
                                    if (release.upc * 1 === reportUPC) {
                                            hasUPC = true
                                        }
                                    })
                                }
                                if (hasUPC === true) {
                                    if (user.payeeID) {
                                        reportPayee = user.payeeID
                                    }
                                }
                        })
                        payeeReports[i]['Payee ID'] = reportPayee
                    }
                    handleDownload(payeeReports, fileID)
                    // console.log(users)
                }
            });
        })
    }

    const convertToCSV = (data) => {
        const headers = Object.keys(data[0]);
        const csvRows = [
            headers.join(','), // header row first
            ...data.map(row => headers.map(header => JSON.stringify(row[header] || '')).join(','))
        ];
        return csvRows.join('\n');
    };
    
    const handleDownload = (data, fileID) => {
        const csvData = convertToCSV(data);
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', fileID);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

  return (
    <div className="admin-page py-5">
        <div className='container'>
            <h1 className='dash-title'>Uploaded Reports</h1>
            <div className="row">
                <div className="col-12 px-1 mt-4">
                    <div className="dash-card">
                        <div className="dash-card-heading">
                            <h4>Reports</h4>
                        </div>
                        <div className="table-responsive mt-4">
                            <table className="table dash-table">
                                <thead>
                                    <tr>
                                        <td>Month</td>
                                        <td>Streams</td>
                                        <td>Revenue</td>
                                        <td># of Lines</td>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        reports.map((report, i) => (
                                            <tr key={i}>
                                                <td><div className='table-item'>{report.monthYears}</div></td>
                                                <td><div className='table-item'>{report.totalStreams}</div></td>
                                                <td><div className='table-item'>${(report.totalPrice * 1).toFixed(2)}</div></td>
                                                <td><div className='table-item'>{report.rows}</div></td>
                                                <td>
                                                    <div className='table-item d-flex'>
                                                        <i 
                                                            className="fi fi-rr-eye table-action-btn a-blue me-2"
                                                            onClick={() => updateOverview(report.overview)}
                                                        ></i>
                                                        <i 
                                                            className="fi fi-rr-download table-action-btn a-green me-2"
                                                            onClick={() => getFileData(report.file, report.monthYears)}
                                                        ></i>
                                                        {/* <a href={report.file} download={report.id}>
                                                            <i className="fi fi-rr-download table-action-btn a-green"></i>
                                                        </a> */}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


        </div>


    </div>
  )
}

export default AdminReportsHistory