import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Bar, Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS } from 'chart.js/auto'
import { child, get, ref, set } from 'firebase/database'
import { db } from '../../firebase'
import { CSVLink } from 'react-csv';
import Pagination from '../Paginations'
import * as echarts from 'echarts';
import ReactEcharts from "echarts-for-react"; 

const AdminAnalytics = () => {

  const uid = localStorage.getItem('uid')
  const [labels, setLabels] = useState([])
  const [reports, setReports] = useState([])
  const [values, setValues] = useState([])
  const [filteredValues, setFilteredValues] = useState([])
  const [yearFilter, setYearFilter] = useState([])
  const [year, setYear] = useState('')
  const [topPlatforms, setTopPlatforms] = useState([])
  const [topCountries, setTopCountries] = useState([])
  const [releasesList, setReleasesList] = useState([])
  const [topReleases, setTopReleases] = useState([])
  const [releases, setReleases] = useState([])
  const [releaseTracks, setReleaseTracks] = useState([])
  const [previewTitle, setPreviewTitle] = useState('')
  const [balance, setBalance] = useState(0)
  const [lastMonthRevenue, setLastMonthRevenue] = useState(0)
  const [platformsData, setPlatformsData] = useState([])

  useEffect(() => {
    get(child(ref(db, 'admin'), 'reports'))
    .then((snapshot) => {
      if (snapshot.val()) {
        setValues(snapshot.val().reports)
        const years = getUniqueYears(snapshot.val().reports)
        filterByYear(snapshot.val().reports, years[years.length - 1])
        setYearFilter(years)
        setYear(years[years.length - 1])
        setLastMonthRevenue(snapshot.val().reports[snapshot.val().reports.length - 1].totalPrice.toFixed(0))
        setTopPlatforms(snapshot.val().sorting.channels)
        setTopCountries(snapshot.val().sorting.countries)
        setTopReleases(snapshot.val().sorting.releases)
        setReleasesList(snapshot.val().groupedReleases)
        setReleases(snapshot.val().groupedReleases)
        setBalance(snapshot.val().balance.toFixed(0) || 0)

        const platformsValues = snapshot.val().sorting.channels.map(platform => {
          let platformName = platform.channel.toLowerCase()

          if (platformName === 'snapshat') platformName = 'Snap'
          else if (platformName === 'apple music / itunes' || platformName === 'itunes' || platformName === 'itunes match') platformName = 'Apple Music'
          else if (platformName.includes('facebook')) platformName = 'Facebook'
          else if (platformName.includes('amazon')) platformName = 'Amazon'
          else if (platformName.includes('tiktok')) platformName = 'TikTok'
          else if (platformName.includes('spotify')) platformName = 'Spotify'
          else platformName = platform.channel
          return {
            value: platform.totalRevenue.toFixed(0),
            name: platformName
          }
        })

        let platformsObj = {}

        platformsValues.forEach(platform => {
          if (platformsObj[platform.name]) {
            platformsObj[platform.name] += platform.value * 1
          } else {
            platformsObj[platform.name] = platform.value * 1
          }
        })

        const platforms = []

        for (let platObj in platformsObj) {
          platforms.push({
            name: platObj,
            value: platformsObj[platObj]
          })
        }

        setPlatformsData(platforms)
      }

    })
  }, [])

  const getUniqueYears = (array) => {
      const uniqueYears = new Set();
      array.forEach(entry => {
          const [month, year] = entry.monthYear.split('/'); // Assuming monthYear is formatted as 'month/year'
          uniqueYears.add(year);
      });
      return Array.from(uniqueYears).sort(); // Sort the years in ascending order
  }

  const filterByYear = (array, selectedYear) => {
    const newValues = array.filter(entry => {
        const [month, year] = entry.monthYear.split('/'); // Assuming monthYear is formatted as 'month/year'
        return year === selectedYear;
    });
    setYear(selectedYear)
    setFilteredValues(newValues)
  }

  const searchRelease = (e) => {
    setReleases(releasesList.filter(release => release.release.toLowerCase().includes(e.target.value.toLowerCase())))
  }

  const ctx = document.createElement('canvas').getContext('2d');
  const gradient = ctx.createLinearGradient(0, 0, 0, 300);
  gradient.addColorStop(0, '#4b95e9');    // Start color (#4b95e9)
  gradient.addColorStop(1, '#4b95e93a');

  const data = {
    labels: filteredValues.map(item => item.monthYear),
    datasets: [
      {
        label: 'Monthly Revenue ($)',
        data: filteredValues.map(item => item.totalPrice.toFixed(0)),
        borderColor: '#337CCF',
        backgroundColor: gradient,
        borderWidth: 1,
      }
    ]
  };
  

  const ChannelsData = {
    labels: topPlatforms.map(platform => platform.channel),
    datasets: [
      {
        data: topPlatforms.map(platform => platform.totalRevenue.toFixed(0)), // Example data values for each category
        backgroundColor: ['#4b95e9', '#e74c3c', '#f39c12', '#16a085'], // Example colors for each category
      },
    ],
  };

  const options = {
    // animation: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = '';
            if (context.formattedValue !== null) {
              label += '$' + context.formattedValue; // Add your unit here
            }
            return label;
          },
        },
      },
    }
  };



  /////// RELEASES PAGINATION ////////
  const [sortOrder, setSortOrder] = useState("asc")

  const handleSort = (property) => {
      setCurrentPage(1)
      const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
      const sortedData = [...releases].sort((a, b) => {
      if (property === "release") {
          return (sortOrder === "asc" ? 1 : -1) * a.release.localeCompare(b.release);
      } else if (property === "revenue") {
          return (sortOrder === "asc" ? 1 : -1) * (a.revenue - b.revenue);
      } else if (property === "streams") {
        return (sortOrder === "asc" ? 1 : -1) * (a.streams - b.streams);
      }
      return 0;
    });

      setReleases(sortedData);
      setSortOrder(newSortOrder);
  };


  /////////// PAGINATION ///////////
  const itemsPerPage = 30;
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = releases.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(releases.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
  };
  


  ///////////// FLATEN RELEASES ///////////////
  
  const flattenReleases = releases.reduce((accumulator, item) => {
    const { release, tracks } = item;
    const flatTracks = tracks.map(track => ({
      release: release,
      track: track.track,
      streams: track.streams,
      revenue: track.revenue
    }));
    return [...accumulator, ...flatTracks];
  }, []);


  const option = {
    color: ['#377099'],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: filteredValues.map(item => item.monthYear)
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: 'Revenue',
        type: 'line',
        stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0
        },
        showSymbol: true,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#377099'
            },
            {
              offset: 1,
              color: '#fff'
            }
          ]),
        },
        emphasis: {
          focus: 'series'
        },
        data: filteredValues.map(item => item.totalPrice.toFixed(0))
      }
    ]
  };


  const option1 = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      // top: '0%',
      left: 'center',
    },
    series: [
      {
        top: '20%',
        name: 'Revenue',
        type: 'pie',
        radius: ['50%', '100%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 15,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: platformsData
      }
    ]
  };


  return (
    <div className='dash-section'>
        <h1 className='dash-title'>Analytics</h1>
        <div className="row">
          <div className="col-lg-4 col-md-6 mt-3">
              <div className="dash-card dash-widget">
                <div className="dash-widget-left">
                  <h1>${balance}</h1>
                  <h5>Current Balance</h5>
                </div>
                <div className="dash-widget-right">
                  <i class="fi fi-sr-donate"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-3">
              <div className="dash-card dash-widget">
                <div className="dash-widget-left">
                  <h1>${lastMonthRevenue}</h1>
                  <h5>Latest Month Revenue</h5>
                </div>
                <div className="dash-widget-right">
                    <i class="fi fi-sr-hand-holding-usd"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-3">
              <div className="dash-card dash-widget">
                <div className="dash-widget-left">
                    <h1>-</h1>
                    <h5>Total Paid</h5>
                </div>
                <div className="dash-widget-right">
                    <i class="fi fi-ss-piggy-bank"></i>
                </div>
              </div>
            </div>
        </div>
        <div className="row">
          <div className="col-12 mt-4">
            <div className="dash-card">
              <div className="dash-card-heading mb-3">
                  <h2 className="dash-card-title">Monthly Revenue</h2>
                  <div className="date-range-selector">
                    {
                      filteredValues.length > 0 && (
                        <div className="dash-form-group">
                          <label>Year</label>
                          <div className="dropdown dash-form-dropdown">
                              <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                  {year}
                              </button>
                              <ul className="dropdown-menu">
                                  {
                                      yearFilter.map((item, i) => (
                                          <li 
                                            key={i}
                                            onClick={() => filterByYear(values, item)}  
                                          >
                                              {item}
                                          </li>
                                      ))
                                  }
                              </ul>
                          </div>
                        </div>
                      )
                    }
                  </div>
              </div>
              {
                filteredValues.length > 0 ? (
                  <ReactEcharts option={option} />
                ) : (
                  <div className="no-data">No Data</div>
                )
              }
            </div>
          </div>
          <div className="col-lg-6 mt-4">
            <div className="dash-card mb-4 sorting-card">
              <div className="dash-card-heading mb-2">
                <h2 className="dash-card-title">Top Countries</h2>
              </div>
              {
                topCountries.length > 0 ? (
                  <div className="top-items">
                    {
                      topCountries.map((country, i) => (
                        <div className="top-item" key={i}>
                          <h6>{i + 1}. {country.country}</h6>
                          <span>${country.totalRevenue.toFixed(2)}</span>
                        </div>
                      ))
                    }
                  </div>
                ) : (
                  <div className="no-data no-data-100">No Data</div>
                )
              }
            </div>
            <div className="dash-card sorting-card">
              <div className="dash-card-heading mb-2">
                <h2 className="dash-card-title">Top Releases</h2>
              </div>
              {
                topReleases.length > 0 ? (
                  <div className="top-items">
                    {
                      topReleases.map((release, i) => (
                        <div className="top-item" key={i}>
                          <h6>{i + 1}. {release.release}</h6>
                          <span>${release.totalRevenue.toFixed(2)}</span>
                        </div>
                      ))
                    }
                  </div>
                ) : (
                  <div className="no-data no-data-100">No Data</div>
                )
              }
            </div>
          </div>
          <div className="col-lg-6 mt-4">
            <div className="dash-card">
              <div className="dash-card-heading mb-3">
                <h2 className="dash-card-title">Top Platforms</h2>
              </div>
              {
                platformsData.length > 0 ? (
                  <ReactEcharts option={option1} />
                ) : (
                  <div className="no-data">No Data</div>
                )
              }
            </div>
          </div>

          <div className="col-12 mt-4">
              <div className="dash-card">
                  <div className="d-flex flex-wrap justify-content-between">
                    <div>
                      <h2 className="dash-card-title">Releases</h2>
                      <input type="text" className='dash-input' onChange={(e) => searchRelease(e)} placeholder='Search...' />
                    </div>
                      <CSVLink className="dash-card-heading-btn" data={flattenReleases} filename={'releases.csv'}>
                        <i className="fi fi-rr-file-export me-2"></i>
                        Export CSV
                      </CSVLink>
                  </div>
                  {
                    releases.length > 0 ? (
                      <>
                        <div className="table-responsive mt-3">
                            <table className="table dash-table">
                                <thead>
                                    <tr>
                                        <td>
                                          <div className='table-sort' onClick={() => handleSort('release')}>
                                            Releases Title
                                            {
                                                sortOrder === "asc" ? <i className="fi fi-ss-angle-small-down"></i>
                                                : <i className="fi fi-ss-angle-small-up"></i>
                                            }
                                          </div>
                                        </td>
                                        <td>
                                          <div className='table-sort' onClick={() => handleSort('streams')}>
                                            Streams
                                            {
                                                sortOrder === "asc" ? <i className="fi fi-ss-angle-small-down"></i>
                                                : <i className="fi fi-ss-angle-small-up"></i>
                                            }
                                          </div>
                                        </td>
                                        <td>
                                          <div className='table-sort' onClick={() => handleSort('revenue')}>
                                            Revenue
                                            {
                                                sortOrder === "asc" ? <i className="fi fi-ss-angle-small-down"></i>
                                                : <i className="fi fi-ss-angle-small-up"></i>
                                            }
                                          </div>
                                        </td>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                  {
                                    currentItems.map((release, i) => (
                                      <tr key={i}>
                                          <td>
                                              <div className="table-item">
                                                  {release.release}
                                              </div>
                                          </td>
                                          <td>
                                              <div className="table-item">
                                                  {release.streams}
                                              </div>
                                          </td>
                                          <td>
                                              <div className="table-item">
                                                  ${release.revenue.toFixed(2)}
                                              </div>
                                          </td>
                                          <td>
                                              <div className="table-item">
                                                  <button 
                                                    className='table-btn' 
                                                    data-bs-toggle="modal"
                                                    data-bs-target='#tracksModal'
                                                    onClick={() => {
                                                      setReleaseTracks(release.tracks);
                                                      setPreviewTitle(release.release);
                                                    }}
                                                  >Details</button>
                                              </div>
                                          </td>
                                      </tr>
                                    ))
                                  }
                                </tbody>
                            </table>
                        </div>
                        {/* <div className="table-pagination">
                            {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                            <button key={pageNumber} className={currentPage === pageNumber && 'active'} onClick={() => handlePageChange(pageNumber)}>
                                {pageNumber}
                            </button>
                            ))}
                        </div> */}
                        <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
                      </>
                    ) : (
                      <div className="no-data">No Data</div>
                    )
                  }
              </div>
          </div>
        </div>

        {/* MODAL */}
        <div className="modal fade" id="tracksModal" tabindex="-1" aria-labelledby="tracksModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                      <div className="modal-top">
                          <h5>{previewTitle} Tracks</h5>
                          <i className="fi fi-rr-cross" data-bs-dismiss="modal"></i>
                      </div>
                  </div>
                  <div className="modal-body">
                    <div className="table-responsive mt-3">
                        <table className="table dash-table">
                            <thead>
                                <tr>
                                    <td>Track Title</td>
                                    <td>Streams</td>
                                    <td>Revenue</td>
                                </tr>
                            </thead>
                            <tbody>
                              {
                                releaseTracks && releaseTracks.map((track, i) => (
                                  <tr key={i}>
                                      <td>
                                          <div className="table-item">
                                              {track.track}
                                          </div>
                                      </td>
                                      <td>
                                          <div className="table-item">
                                              {track.streams}
                                          </div>
                                      </td>
                                      <td>
                                          <div className="table-item">
                                              ${(track.revenue * 1).toFixed(2)} 
                                              {
                                                track.share !== 100 && <span className='track-split-text'>({track.share}% Split)</span>
                                              }
                                          </div>
                                      </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                        </table>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AdminAnalytics