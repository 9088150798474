import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { db } from '../../firebase';
import { child, equalTo, get, orderByChild, query, ref } from 'firebase/database';
import Pagination from '../Paginations';


const AdminReportsDetails = () => {

    const uid = localStorage.getItem('uid')
    const reports = JSON.parse(localStorage.getItem('reportOverview'))


    /////////// PAGINATION ///////////
    const itemsPerPage = 50;
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = reports.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(reports.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


  return (
    <div className='dash-section'>
        <h1 className='dash-title'>Uploaded Reports</h1>
        <div className="row">
            <div className="col-12 mt-4">
                <div className="dash-card">
                    <h2 className="dash-card-title">
                        <Link to={'/admin/reportsHistory'} className='title-back-link'>
                            <i className="fi fi-rr-arrow-small-left"></i>
                        </Link>
                        Report Overview
                    </h2>
                    <div className="table-responsive mt-3">
                        <table className="table dash-table">
                            <thead>
                                <tr>
                                    <td>Email</td>
                                    <td>Name</td>
                                    <td>Payee ID</td>
                                    <td>Total Streams</td>
                                    <td>Net Revenue</td>
                                    <td>Gross Revenue</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    currentItems.map((user, i) => (
                                        <tr key={i}>
                                            <td>{user.email}</td>
                                            <td>{user.name}</td>
                                            <td>{user.payeeID}</td>
                                            <td>{user.totalStreams}</td>
                                            <td>${user.totalNetRevenue}</td>
                                            <td>${user.totalGrossRevenue}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    {/* <div className="table-pagination">
                        {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                        <button key={pageNumber} className={currentPage === pageNumber && 'active'} onClick={() => handlePageChange(pageNumber)}>
                            {pageNumber}
                        </button>
                        ))}
                    </div> */}
                    <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
                </div>
            </div>
        </div>

    </div>
  )
}

export default AdminReportsDetails