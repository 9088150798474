import React, { useRef, useState } from 'react'
import logo from "../assets/logo.svg";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import '../sass/auth.scss'
import { auth } from '../firebase';
import { confirmPasswordReset, sendPasswordResetEmail, signInWithEmailAndPassword, updatePassword } from 'firebase/auth';

const UpdatePassword = () => {

    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const successBtn = useRef()
    // const { oobCode } = useParams();
    const location = useLocation();
    const oobCode = new URLSearchParams(location.search).get('oobCode')

    const [error, setError] = useState('')

    const handleError = (errorMsg) => {
        setError(errorMsg)
        setTimeout(() => {
            setError('')
        }, 3000);
    }


    const handleUpdate = (e) => {
        e.preventDefault();
        if (newPassword === confirmPassword) {
            confirmPasswordReset(auth, oobCode, newPassword)
            .then(() => successBtn.current.click())
            .catch(error => handleError(error.message))
        } else {
            handleError('Passwords do not match. Please re-confirm your password.')
        }
    }


  return (
    <div className='auth-page'>

        <form className="container py-5" onSubmit={handleUpdate}>
            <div className="row">
                <div className="col-lg-6 auth-container">
                    <Link to={'/'} className='auth-logo'>
                        <img src={logo} alt="" />
                    </Link>

                    <h1>Update Password</h1>
                    <h6>
                        Secure Your Account with a New Password
                    </h6>
                    <div className='auth-form'>
                        <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} className='auth-form-input' placeholder='New Password' required />
                        <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className='auth-form-input' placeholder='Confirm Password' required />
                    </div>

                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-lg-6'>
                    {
                        error !== '' && <h5 className='text-danger text-center mb-3'>{error}</h5>
                    }
                    <button className='auth-form-btn' type='submit'>
                        <span>Update Password</span>
                    </button>
                </div>
            </div>
        </form>


        <button ref={successBtn} data-bs-toggle="modal" data-bs-target="#successModal" hidden></button>
        <div className="modal fade" id="successModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="successModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a href="/login" className='success-close-btn'>
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Password Updated Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default UpdatePassword